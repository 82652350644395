import React from 'react';
import './IrMenu.css';
import { useHistory } from "react-router-dom";

const IrMenu = () => {
    const history = useHistory();


        const handleClick =() =>{ 
        
            history.push(
                process.env.REACT_APP_BASE_URL + "documentSelector"
           );
        }
    return (
        <>
            <div className='contenedorirmenu animated animatedFadeInUp fadeInUp dos'>
                    <button className="button-other-certificate" onClick={handleClick}>
                    <i class="fa-solid fa-arrow-rotate-left"></i>&nbsp;
                    Otro Certificado
                </button>
            </div>
        </>
    )
};

export default IrMenu;