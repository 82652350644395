import React, { useState, useEffect, useContext } from "react";
import "./CertificateMarriageOptions.css";
import { Container, Row, Col } from "react-bootstrap";
import IrMenu from "../../../../commons/components/Buttons/IrMenu/IrMenu";
import Close from "../../../../commons/components/Buttons/Close/Close";
import NameUser from "../../../../commons/components/UserNames/NameUser/NameUser";
import { useHistory } from "react-router-dom";
import AppContext from "../../../../commons/components/AppContext/AppContext";

const CertificateMarriageOptions = ({ setShowErrorMessage, setShowWaiting }) => {
     const [data, setData] = useState([]);

     const history = useHistory();
     const globalContext = useContext(AppContext);
     useEffect(() => {
          setData(globalContext.matrimonios);
     }, [globalContext.matrimonios]); // eslint-disable-line react-hooks/exhaustive-deps

     const handleClick = async (NumInscripcion) => {
          //set global purchase..

          const compra={
               typeDocument:"matrimonio",
               certificateId:NumInscripcion,
               name:globalContext.person?.Nombres,
               lastName: globalContext.person?.PrimerApellido+" "+globalContext.person?.SegundoApellido,
               dniSolicitante:globalContext.person?.NumInscripcion
          }    
          
          globalContext.setCompra(compra)
          history.push(process.env.REACT_APP_BASE_URL + "marriagePay");

     };
     return (
          <>
               <div className="center-screen">
                    <Container className="mincertificado">
                         <NameUser></NameUser>
                         <p class="textgeneralinstrution animated animatedFadeInUp fadeInUp dos">Selecciona el certificado que deseas imprimir</p>
                         <Row className="align-items-center animated animatedFadeInUp fadeInUp dos">
                              <Col>
                                   <div className="descendencialist">
                                        <div className="contenedorscroll scrollbar">
                                             <ul className="ulcontent">
                                             {data.length > 0 ? (
                                                  data.map((item, index) => (
                                                       <li key={index}>
                                                            <img className="icondescarga" src={require("../../../../assets/images/icondescargar.png")} alt="Descarga" />
                                                            <div className="contentparent">
                                                                 <h2>{`${item.NombresVaron} / ${item.NombresMujer}`}</h2>
                                                                 <p>{`#Inscripcion: ${item.NumInscripcion}`}</p>
                                                            </div>
                                                            <a onClick={() => handleClick(item.NumInscripcion)}>Comprar</a>
                                                       </li>
                                                  ))
                                                  ) : (
                                                  <li></li>
                                                  )}
                                             </ul>
                                        </div>
                                   </div>

                                   <div className="alignbtnfooter">
                                        <div className="center">
                                             <IrMenu></IrMenu>
                                             <Close></Close>
                                        </div>
                                   </div>
                              </Col>
                         </Row>
                    </Container>
               </div>
          </>
     );
};

export default CertificateMarriageOptions;
