import React from 'react';
import './Process.css';
import CargadorAnimado from '../../components/CargadorAnimado/CargadorAnimado';

const Process = ({ show, message, imageanimation, _classprocess }) => {
   
    if (!show) {
        return null; // Si show es false, no renderiza nada
    }
    return (
        <>
         <div className='waiting-overlay'>
            <div className='waiting-content'>      
                <img src={imageanimation} alt="Alert" className={_classprocess}/>         
                <div className='loading-text messageanimation'>{message}</div>
                <CargadorAnimado>  </CargadorAnimado>
            </div>
        </div>
        </>
    )
};

export default Process;