import React, { useContext } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import AppContext from '../../commons/components/AppContext/AppContext';
import './Home.css';
import ActiveKiosko from '../ActiveKiosko/ActiveKiosko';
import Welcome from '../Welcome/Welcome';
import Authentication from '../Authentication/Authentication';
import DocumentSelector from '../DocumentSelector/DocumentSelector';
import CertificateSelector from '../CertificateSelector/CertificateSelector';
import PreviewCertificate from '../PreviewCertificate/PreviewCertificate';
import DescendenciaCertificate from '../DescendenciaCertificate/DescendenciaCertificate';
import CertificateMarriage from '../CertificateMarriage/CertificateMarriage';
import PreviewMarriage from '../PreviewMarriage/PreviewMarriage';
import SearchDeath from '../SearchDeath/SearchDeath';
import DeathPreview from '../PreviewDeath/PreviewDeath';
import PreviewRepoDNI from '../PreviewRepoDNI/PreviewRepoDNI';
import PayRepoDNI from '../PayRepoDNI/PayRepoDNI';
import PayMarriage from '../CertificateMarriage/components/PayMarriage/PayMarriage';
import Cierre from '../Cierre/Cierre';
import PayDeath from '../SearchDeath/components/PayDeath/PayDeath';
import TestVpos from '../TestVPOS/TestVpos';
import DNIRepo from '../DNIRepo/DNIRepo';
import PreviewDNIRepo from '../DNIRepo/components/PreviewDNIRepo/PreviewDNIRepo';
// import TestHuella from '../TestHuella/TestHuella'; 

import AlertError from '../Alerta/Alerta';
import EnvSignal from './EnvSignal/EnvSignal';
import TopBar from './Navbar/TopBar';
import TestHuella from '../TestHuella/TestHuella';
import GeneralInactividad from '../../commons/components/GeneralInactividad/GeneralInactividad';

const Home = () => {
    const isDebug = process.env.REACT_APP_IS_DEBUG === 'true';

    const globalContext = useContext(AppContext);
    return (
        <>
           

            {isDebug && <EnvSignal></EnvSignal>}

            <Container fluid className='tamanocuadrado'>
                <TopBar/>
                <Row>
                            <BrowserRouter>
                                <Switch>
                                    <Route exact path={process.env.REACT_APP_BASE_URL} component={ActiveKiosko} />
                                    <Route exact path={process.env.REACT_APP_BASE_URL+ "welcome"} component={Welcome} />
                                    <Route exact path={process.env.REACT_APP_BASE_URL + "authentication"} component={Authentication} />
                                    <Route exact path={process.env.REACT_APP_BASE_URL + "documentSelector"} component={DocumentSelector} />
                                    <Route exact path={process.env.REACT_APP_BASE_URL + "certificateSelector"} component={CertificateSelector} />
                                    <Route exact path={process.env.REACT_APP_BASE_URL + "previewCertificate"} component={PreviewCertificate} />
                                    {/* <Route exact path={process.env.REACT_APP_BASE_URL + "certificateBirthSelector"} component={CertificateBirthSelector} /> */}
                                    <Route exact path={process.env.REACT_APP_BASE_URL + "decencyCertificate"} component={DescendenciaCertificate} />
                                    <Route exact path={process.env.REACT_APP_BASE_URL + "marriageCertificate"} component={CertificateMarriage} />
                                    <Route exact path={process.env.REACT_APP_BASE_URL + "marriagePreview"} component={PreviewMarriage} />
                                    <Route exact path={process.env.REACT_APP_BASE_URL + "searchDeath"} component={SearchDeath} />
                                    <Route exact path={process.env.REACT_APP_BASE_URL + "deathPreview"} component={DeathPreview} />
                                    <Route exact path={process.env.REACT_APP_BASE_URL + "previewdnirepo"} component={PreviewDNIRepo} />
                                    <Route exact path={process.env.REACT_APP_BASE_URL + "DNIRepo"} component={DNIRepo} />
                                    <Route exact path={process.env.REACT_APP_BASE_URL + "marriagePay"} component={PayMarriage} />
                                    <Route exact path={process.env.REACT_APP_BASE_URL + "cierreApp"} component={Cierre} />
                                    <Route exact path={process.env.REACT_APP_BASE_URL + "payDeath"} component={PayDeath} />
                                    <Route exact path={process.env.REACT_APP_BASE_URL + "testVpos"} component={TestVpos} />
                                    <Route exact path={process.env.REACT_APP_BASE_URL + "alertError"} component={AlertError} />
                                    <Route exact path={process.env.REACT_APP_BASE_URL + "testHuella"} component={TestHuella} />
                                    <Route exact path={process.env.REACT_APP_BASE_URL + "mensajeInactivo"} component={GeneralInactividad} />

                                    
                                </Switch>
                            </BrowserRouter>
                    </Row>

              

            </Container>

        </>
    )
};

export default Home;