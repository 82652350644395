import React, { useState } from "react";
import axios from "axios";
import { saveAs } from "file-saver"; // Importa esta función para la descarga
import zipPath from "../../assets/vpos/emulador_01.zip"; // Cambia esta ruta por la correcta

const TestVpos = () => {
     const [formData, setFormData] = useState({
          id: "1",
          claIDTran: "1",
          monto: "5098",
          last4: "2343",
          expDate: "0119",
          Currency: "1",
          PayEntryMode: "1",
          Tax1: "123",
          Tax2: "123",
          Discount: "123",
          Tip: "123",
          BaseAmount: "123",
     });

     const [log, setLog] = useState("");

     const handleInputChange = (event) => {
          const { name, value } = event.target;
          setFormData({ ...formData, [name]: value });
     };
     const [countTest, setCountTest] = useState(0); // Estado para countTest

     const sendJsonToServer = async () => {
          const updatedCount = countTest + 1; // Incrementar countTest
          setCountTest(updatedCount); // Actualizar countTest en el estado

          const currentDate = new Date();
          const formattedDate = `${currentDate.getDate()}/${currentDate.getMonth() + 1}/${currentDate.getFullYear()} ${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}`;

          const line = `---------------[Prueba # ${updatedCount} -> ${formattedDate}]----------------------------`;

          try {
               const response = await axios.post("http://localhost:3004/socketBridge", formData);
               const logMessage = JSON.stringify(response.data, null, 2);
               setLog(line + "\n Enviado" + JSON.stringify(formData, null, 2).toString() + "\n Recibido:" + logMessage + "\n\n" + log);
          } catch (error) {
               console.error("Error:", error);
               const errorMessage = `Error al enviar JSON: ${error.message}`;
               setLog(line + "\n" + log + errorMessage + "\n\n");
          }
     };

     const downloadTxtFile = () => {
          const currentDate = new Date();
          const formattedDate = `${currentDate.getDate()}-${currentDate.getMonth() + 1}-${currentDate.getFullYear()} ${currentDate.getHours()} ${currentDate.getMinutes()} ${currentDate.getSeconds()}`;

          const blob = new Blob([log], { type: "text/plain" });
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.download = "log " + formattedDate + ".txt";
          link.href = url;
          link.click();
     };
     const downloadEmulador = () => {
      // Ruta al archivo ZIP en tus activos

    // Descarga el archivo ZIP
    axios
      .get(zipPath, { responseType: "blob" })
      .then((response) => {
        saveAs(response.data, "Emulador.zip");
      })
      .catch((error) => {
        console.error("Error al descargar el emulador:", error);
      });
    };
     return (
          <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
               <h2>Test de VPOS</h2>
               <div style={{ display: "flex", width: "100%" }}>
                    <div style={{ flex: "1 1 50%", paddingRight: "20px" }}>
                         {Object.keys(formData).map((key) => (
                              <div key={key} style={{ display: "flex", marginBottom: "10px" }}>
                                   <label style={{ flex: "1" }}>{key}:</label>
                                   <input type="text" name={key} value={formData[key]} onChange={handleInputChange} style={{ flex: "2", padding: "5px" }} />
                              </div>
                         ))}
                         <button type="button" onClick={() => sendJsonToServer()}>
                              Enviar JSON
                         </button>
                    </div>
                    <div style={{ flex: "1 1 50%" }}>
                         <h3>Registro de Respuestas:</h3>
                         <div style={{ marginTop: "10px" }}>
                              <button type="button" onClick={downloadEmulador}>
                                   Descargar Emulador
                              </button>
                              <button type="button" onClick={downloadTxtFile}>
                                   Descargar como archivo .txt
                              </button>
                         </div>
                         <textarea value={log} rows="10" cols="50" readOnly style={{ width: "100%", height: "calc(100vh - 110px)", padding: "5px" }} />
                    </div>
               </div>
          </div>
     );
};

export default TestVpos;
