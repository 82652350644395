import React, { useState, useEffect } from "react";
import './ImpresionMarriagePdf.css';



const ImpresionMarriagePdf = ({_onClick}) => {

    return (
        <>
            <div className='contenedorcertificado animated animatedFadeInUp fadeInUp dos' onClick={_onClick}>
                    <a className='contbtncerthor btnicon'>
                        <p class="textbtncert"><img className="icondescarga" src={require('../../../../../../assets/images/iconimpresion.png')} alt="Impresion" /> Imprimir</p>                          
                    </a>  
            </div>
        </>
    )
};

export default ImpresionMarriagePdf;