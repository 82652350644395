import React, { useState, useContext, useEffect } from "react";
import "./PayMarriage.css";
import GeneralPay from "../../../../commons/components/GeneralPay/GeneralPay";
import marriageIcon from "../../../../assets/images/certmatrimonio.png";
import { useHistory } from "react-router-dom";

import AppContext from "../../../../commons/components/AppContext/AppContext";
import GeneralMessage from "../../../../commons/components/GeneralMessage/GeneralMessage";
import Wainting from "../../../../commons/components/Waiting/Waiting";
import Animationwaiting from "../../../../assets/images/PagosAnimacion.gif";
import AnimationPOS from "../../../../assets/images/pinpad.gif";
import InstruccionesPago from "../../../../assets/images/instruccionesPago.png";
import { getDataPinPad } from "../../../../Services/services";
import { checkout, checkoutCode, validateStatusTGR ,updateAsDelivered, storeBitacora } from "../../../../Services/services";
import { invokeCreateTGR } from "../../../../Services/InvokeServices";
import { sentToEM } from "../../../../Services/services";
import ErrorMessage from "../../../../commons/components/ErrorMessage/ErrorMessage";
import ErrorMessageAuto from "../../../../commons/components/ErrorMessage/ErrorMessageAuto";
import icoOk from "../../../../assets/images/iconcheck.png";
import icoF from "../../../../assets/images/iconalert.png";
import icoB from "../../../../assets/images/reintentandoicono.png";
import iconGetTGR from "../../../../assets/images/iconsearch.png"
import Process from "../../../../commons/components/Process/Process";


const PayMarriage = () => {
     const [showWaiting, setShowWaiting] = useState(false);
     const [showWaitingGetTGR, setShowWaitingGetTGR] = useState(false);
     const [showMessageWaitingGetTGR, setShowMessageWaitingGetTGR] = useState('Un momento por favor. Obteniendo el costo del documento...');
     const [showErrorMessage, setShowErrorMessage] = useState(false);
     const globalContext = useContext(AppContext);
     const [receivedMessage, setReceivedMessage] = useState("");
     const [icon, setIcon] = useState(InstruccionesPago);
     const [proccessPayMsg, setProccessPayMsg] = useState("Por favor, acercar la tarjeta al POS cuando la luz esté verde");
     const [paymentResponsePinPad, setPaymentResponsePinPad] = useState("");
     const [retryCounter, setRetryCounter] = useState(0);
     const [showErrorPaymentMessage, setShowErrorPaymentMessage] = useState(false);
     const [resultCreateTGR, setResultCreateTGR] = useState(null);
     const [showErrorMessageUpdate, setShowErrorMessageUpdate] = useState(false);
     const [receivedMessageUpdate, setReceivedMessageUpdate] = useState("");
     const [showErrorAmountMessage, setShowErrorAmountMessage] = useState(false);
     const [showMessageTGR, setShowMessageTGR] = useState(false);
     const [messageErrorTGR, setMessageErrorTGR] = useState('El TGR no es valido')
     const [lastID, setLastID] = useState(0);
     const [classImgImpresion, setClassImgImpresion] = useState('imgimpresion');
     const history = useHistory();

     useEffect(() => {
          //se obtiene el precio a cobrar para el TGR
          const fetchTGR = async () => {
            setShowWaitingGetTGR(true)
            const objCreateTGR = {
              dniSolicitante: globalContext.compra.dniSolicitante
            };
            console.log("objCreateTGR",objCreateTGR)
            try {
               const result=await invokeCreateTGR(objCreateTGR);
               console.log('Monto', result.result.Monto)
               if(result.result.Monto == 0 || result.result.Monto == "0"){
                    const bitacora = {
                         crearReciboTGR1: {
                             status: false,
                             data: result,
                             timestamp: new Date().toISOString(),
                             dni: globalContext.dni,
                         }
                     };
                     await storeBitacora(JSON.stringify(bitacora), globalContext.id_bitacora, true, globalContext.dni);
                    //setResultCreateTGR(result);
                    setShowWaitingGetTGR(false)
                    setShowErrorAmountMessage(true)
               }else{
                    const bitacora = {
                         crearReciboTGR1: {
                             status: true,
                             data: result,
                             timestamp: new Date().toISOString(),
                             dni: globalContext.dni,
                         }
                     };
                     await storeBitacora(JSON.stringify(bitacora), globalContext.id_bitacora, true, globalContext.dni);
                     setResultCreateTGR(result);
                     setShowWaitingGetTGR(false)
                    console.log("Resultado Crear TGR",result)
               }
            } catch (error) {
               const errorMessage = error.response?.data?.message || error.message || 'Error desconocido';
               const bitacora = {
                    crearReciboTGR1: {
                        status: false,
                        data: errorMessage,
                        timestamp: new Date().toISOString(),
                        dni: globalContext.dni,
                    }
                };
                await storeBitacora(JSON.stringify(bitacora), globalContext.id_bitacora, true, globalContext.dni);
               setShowWaitingGetTGR(false)
              console.error('Error al invocar createTGR:', error);
              //history.goBack();
              setShowErrorAmountMessage(true)
            }
          };
          fetchTGR();
     }, [globalContext.compra.dniSolicitante]);

     function formatAmount(amount) {
          // Verifica si el monto es un número válido
          if (!isNaN(parseFloat(amount)) && isFinite(amount)) {
            return amount + "00";
          } else {
            console.error("El monto proporcionado no es un número válido");
            return amount;
          }
     }
     const sendToSocket = async () => {
          try {
               const result = await getDataPinPad();
               console.log("result getDataPinPad", result);
               if (result && result?.status) {
                    const bitacora = {
                         getDataPinPad: {
                             status: true,
                             data: result,
                             timestamp: new Date().toISOString(),
                             dni: globalContext.dni,
                         }
                     };
                     await storeBitacora(JSON.stringify(bitacora), globalContext.id_bitacora, true, globalContext.dni);
                    //TODO VE para los datos que se envian al PINPAD
                    const amount = formatAmount(resultCreateTGR?.result.monto)
                    const jsonData = {
                         id: 1,
                         claIDTran: result.data.claIDTran,
                         monto: amount,
                         last4: "",
                         expDate: "",
                         Currency: "1",
                         //MerchantID: "1",
                         PayEntryMode: "7",
                         Tax1: "0",//000
                         Tax2: "0",//000
                         Discount: "0",//vacio
                         Tip: "0",//000
                         BaseAmount: amount,
                    };
                    console.log("PinpadJSON",jsonData)
                    const resultPay = await sentToEM(jsonData);
                    console.log("resultPay ", resultPay);
                    return resultPay;
               } else {
                    const bitacora = {
                         getDataPinPad: {
                             status: false,
                             data: result,
                             timestamp: new Date().toISOString(),
                             dni: globalContext.dni,
                         }
                     };
                     await storeBitacora(JSON.stringify(bitacora), globalContext.id_bitacora, true, globalContext.dni);
                    return null;
               }
          } catch (error) {
               const bitacora = {
                    getDataPinPad: {
                        status: false,
                        data: error,
                        timestamp: new Date().toISOString(),
                        dni: globalContext.dni,
                    }
                };
                await storeBitacora(JSON.stringify(bitacora), globalContext.id_bitacora, true, globalContext.dni);
               setShowWaiting(false)
          }
     };
     const middleMessage = () => {
          const timer = setTimeout(() => {
               setProccessPayMsg("Agradecemos su espera...");
          }, 40000);
     };

     const sleep = (ms) => {
          return new Promise(resolve => setTimeout(resolve, ms));
      }

     let contador = 0

     async function setDocumentAsDelivered() {
          let maxRetries = 6;
          
          while (contador < maxRetries) {
              try {
               setProccessPayMsg("Reintentando, un momento, por favor...");
               setIcon(icoB);
               setShowWaiting(true);
                  await updateAsDelivered(lastID);
                  setShowWaiting(false);
                  history.push(process.env.REACT_APP_BASE_URL + "marriagePreview");
                  console.log("El documento se ha marcado como entregado correctamente.");
                  return; // Salir de la función si la operación fue exitosa
              } catch (error) {
                  setRetryCounter(retryCounter => retryCounter + 1);
                  console.error("Error al marcar el documento como entregado:", error);
                  setShowErrorMessageUpdate(false);
                  contador++
                  if (contador >= maxRetries) {
                      history.push(process.env.REACT_APP_BASE_URL + "alertError");
                      return; // Salir de la función si se alcanzó el número máximo de reintentos
                  }
                  console.log('Contado', contador)
                  await sleep(5000);
              }
          }
      }

     const tryTogetDataFromRNP = async (jsonData, retry = false) => {
          let response;
          try {
               if (retry) {
                    setProccessPayMsg("Reintentando, un momento, por favor...");
                    setIcon(icoB);
                    middleMessage()
               } else {
                    setProccessPayMsg("¡Pago exitoso! Un momento, por favor...");
                    setIcon(icoOk);
                    middleMessage()
               }
               const fullName = globalContext.compra?.name + " " + globalContext.compra?.lastName;
               globalContext.setResponseVPOS({ ...jsonData, fullName: fullName, description: "Certificación de matrimonio" });
               let obj = {
                    responseVpos: jsonData,
                    compra: globalContext.compra,
               };
               console.log("Obj a checkout", obj);
               response = await checkout(obj,resultCreateTGR, globalContext.id_bitacora);
               if (response != false) {
                    globalContext.setCertificate(response.response);
                    const responseUpdateDelivered = await updateAsDelivered(response.lastID); //sete el documento como entregado
                    const bitacoraDelivered = {
                         updateAsDelivered: {
                            status: true,
                            data: responseUpdateDelivered,
                            timestamp: new Date().toISOString(),
                            dni: globalContext.dni,
                        }
                    };
                    await storeBitacora(JSON.stringify(bitacoraDelivered), globalContext.id_bitacora, true, globalContext.dni);
                    setShowWaiting(false);
                    history.push(process.env.REACT_APP_BASE_URL + "marriagePreview");
               } else {
                    setShowWaiting(false);
                    console.log("response", response);
                    console.log(retryCounter);
                    if (retryCounter >= 6) {
                         history.push(process.env.REACT_APP_BASE_URL + "alertError");
                         return
                    }
                    setReceivedMessage("Hubo un error validando su pago contra el RNP.");
                    setShowErrorMessage(true);
               }
          } catch (error) {
                // Manejar el error aquí
                console.error("Error en tryTogetDataFromRNP:", error);
                if (response && response !== false && response.lastID) {
                     console.log('Si se pago pero no se actualizo el updateDelivery')
                     setShowErrorMessageUpdate(true);
                     setReceivedMessageUpdate("Hubo un error al procesar su solicitud. Por favor, inténtelo de nuevo.");
                     globalContext.setCertificate(response.response);
                     setLastID(response.lastID)
                     setShowWaiting(false);
                 }else{
                    setShowWaiting(false);
                    setReceivedMessage("Hubo un error validando su pago contra el RNP.");
                    setShowErrorMessage(true);
                    if (retryCounter >= 6) {
                         history.push(process.env.REACT_APP_BASE_URL + "alertError");
                         return
                    }
                }
          }
     }

     const payNow = async () => {
          const amount = formatAmount(resultCreateTGR?.result.monto)

          if(amount == 0 || amount == '' || amount == undefined){
               setShowErrorAmountMessage(true)
               return
          }
          
          setIcon(InstruccionesPago)
          setClassImgImpresion('imginstruccion')
          setShowWaiting(true);
          console.log("Enviando PAGO al PIN PAD");

          // Agregar un retraso de 5 segundos antes de cambiar el mensaje y el icono
          await new Promise(resolve => setTimeout(resolve, 5000));
      
          setProccessPayMsg('Pago en proceso');
          setClassImgImpresion('imgimpresion')
          setIcon(Animationwaiting);
          
          const jsonData = await sendToSocket();
          console.log("jsonData",jsonData)
          if (jsonData==null|| jsonData.status===false || jsonData.id == 0 || (jsonData.authNum && jsonData.authNum.startsWith("ERR"))) {
               const bitacora = {
                    payPinPad: {
                        status: false,
                        data: jsonData,
                        timestamp: new Date().toISOString(),
                        dni: globalContext.dni,
                    }
                };
                await storeBitacora(JSON.stringify(bitacora), globalContext.id_bitacora, true, globalContext.dni);
               setShowWaiting(false);
               setShowErrorPaymentMessage(true);
          } else {
               const bitacora = {
                    payPinPad: {
                        status: true,
                        data: jsonData,
                        timestamp: new Date().toISOString(),
                        dni: globalContext.dni,
                    }
                };
                await storeBitacora(JSON.stringify(bitacora), globalContext.id_bitacora, true, globalContext.dni);
               console.log('payNowJsonData', jsonData)
               setPaymentResponsePinPad(jsonData);
               tryTogetDataFromRNP(jsonData);
          }
     };

     const payWithCode = async (codigo) => {
          try {
              const amount = formatAmount(resultCreateTGR?.result.monto);
              setShowMessageWaitingGetTGR('Validando el estado del TGR, por favor espere...');
              setShowWaitingGetTGR(true);
      
              // Preparar datos para la validación del código TGR
              const fullName = globalContext.compra?.name + " " + globalContext.compra?.lastName;
              const obj_validar = {
                  tgr: codigo,
                  estado: 'CANJEADO',
                  nombreEnRecibo: fullName,
                  monto: amount,
                  dniSolicitante: globalContext.compra.dniSolicitante
              };
      
              // Validar el código TGR
              try {
                    const responseValidate = await validateStatusTGR(codigo)
                    const responseValidateParse = JSON.parse(responseValidate.body)
                    if(responseValidateParse && responseValidateParse.status == true){
                         const bitacora = {
                              RecuperarReciboTGR1: {
                                  status: true,
                                  data: responseValidate,
                                  timestamp: new Date().toISOString(),
                                  dni: globalContext.dni,
                              }
                          };
                          await storeBitacora(JSON.stringify(bitacora), globalContext.id_bitacora, true, globalContext.dni);
                      // Si la validación es exitosa, proceder con el checkout
                      let responseVpos = { "authNum": "000000", "Brand": "CODETGR", "Amount": "0000000" + amount, "numReceipt": "0000000", "Reference": "0000000" };
                      let obj = {
                          responseVpos: responseVpos,
                          compra: globalContext.compra,
                      };
                      const datos_bitacora = { dni : globalContext.dni, id_bitacora : globalContext.id_bitacora }
                      const response = await checkoutCode(obj, codigo, datos_bitacora);
                      if (response.response) {
                          globalContext.setResponseVPOS({ ...responseVpos, fullName: fullName, description: 'Certificación de matrimonio' });
                          globalContext.setCertificate(response.response);
      
                          // Función para intentar actualizar como entregado con reintento automático
                          const tryUpdateAsDelivered = async (lastID, attempt = 1) => {
                              try {
                                   const responseUpdateDelivered = await updateAsDelivered(lastID, obj_validar); // Intentar actualizar como entregado
                                   const bitacoraDelivered = {
                                        updateAsDelivered: {
                                        status: true,
                                        data: responseUpdateDelivered,
                                        timestamp: new Date().toISOString(),
                                        dni: globalContext.dni,
                                   }
                                   };
                              await storeBitacora(JSON.stringify(bitacoraDelivered), globalContext.id_bitacora, true, globalContext.dni);
                                  history.push(process.env.REACT_APP_BASE_URL + "marriagePreview");
                              } catch (error) {
                                   const bitacoraDelivered = {
                                        updateAsDelivered: {
                                        status: false,
                                        data: error,
                                        timestamp: new Date().toISOString(),
                                        dni: globalContext.dni,
                                   }
                                   };
                              await storeBitacora(JSON.stringify(bitacoraDelivered), globalContext.id_bitacora, true, globalContext.dni);
                                  console.error('Error al actualizar como entregado', error);
                                  if (attempt < 6) {
                                      await new Promise(resolve => setTimeout(resolve, 5000));
                                      await tryUpdateAsDelivered(lastID, attempt + 1); // Reintentar si no se ha superado el límite de intentos
                                  } else {
                                      history.push(process.env.REACT_APP_BASE_URL + "alertError"); // Redirigir a página de error después de 6 intentos fallidos
                                      return false
                                  }
                              }
                          };
      
                          // Llamar a la función para intentar actualizar como entregado
                          await tryUpdateAsDelivered(response.lastID);
                      } else {
                          if (retryCounter >= 6) {
                              history.push(process.env.REACT_APP_BASE_URL + "alertError");
                              return
                          }
                          setMessageErrorTGR('Hubo un error al procesar el código TGR. Inténtalo otra vez.');
                          setShowWaitingGetTGR(false);
                          setShowMessageTGR(true);
                      }
                  } else {
                    const bitacora = {
                         RecuperarReciboTGR1: {
                             status: false,
                             data: responseValidate,
                             timestamp: new Date().toISOString(),
                             dni: globalContext.dni,
                         }
                     };
                     await storeBitacora(JSON.stringify(bitacora), globalContext.id_bitacora, true, globalContext.dni);
                    setMessageErrorTGR('El código TGR ingresado no está pagado o no existe en nuestros registros. Por favor, verifica la información ingresada e intenta nuevamente.');
                      setShowMessageTGR(true);
                      setShowWaitingGetTGR(false);
                  }
              } catch (error) {
               const bitacora = {
                    RecuperarReciboTGR1: {
                        status: false,
                        data: error,
                        timestamp: new Date().toISOString(),
                        dni: globalContext.dni,
                    }
                };
                await storeBitacora(JSON.stringify(bitacora), globalContext.id_bitacora, true, globalContext.dni);
                  setMessageErrorTGR('Hubo un error al procesar el código TGR. Inténtalo otra vez.');
                  setShowMessageTGR(true);
                  setShowWaitingGetTGR(false);
              }
          } catch (error) {
               const bitacora = {
                    RecuperarReciboTGR1: {
                        status: false,
                        data: error,
                        timestamp: new Date().toISOString(),
                        dni: globalContext.dni,
                    }
                };
                await storeBitacora(JSON.stringify(bitacora), globalContext.id_bitacora, true, globalContext.dni);
              console.log('error', error);
              setMessageErrorTGR('Hubo un error al procesar el código TGR. Inténtalo otra vez.');
              setShowMessageTGR(true);
              setShowWaitingGetTGR(false);
          }
      };
      
   
     const onAccept = async () => {
          if (paymentResponsePinPad != null) {
               setShowErrorMessage(false);
               setShowWaiting(true);
               tryTogetDataFromRNP(paymentResponsePinPad, true);
               setRetryCounter(retryCounter + 1);
          }
     };
     const onAcceptFailPayment = () => {
          setShowErrorPaymentMessage(false);
     };

     const onAcceptFailAmount = () => {
          setShowErrorAmountMessage(false)
          history.goBack();
          return
     }

     const onAcceptTRG = () => {
          setShowMessageTGR(false)
     }

     return (
          <>
               <GeneralPay
                    onClickFun={payNow}
                    onClickpayWithCode={payWithCode}
                    purchase={globalContext.compra}
                    icon={marriageIcon}
                    detail="Certificación de matrimonio"
                    nameligth="Certificado de"
                    namebold=""
                    nameorange="Matrimonio"
                    description="Para imprimir la certificación de matrimonio debes realizar el pago respectivo."
                    amount={resultCreateTGR?.result.monto ?? "0"}
               ></GeneralPay>
               {/* <GeneralMessage show={showWaiting} message="" onAccept={} imageicon={} ></GeneralMessage>> */}
               <ErrorMessageAuto show={showErrorMessage} message={receivedMessage} onAccept={onAccept} labelButton="Reintentar" imageicon={icoF}></ErrorMessageAuto>
               <ErrorMessage show={showErrorPaymentMessage} message="El pago no pudo ser procesado. Por favor, inténtelo nuevamente." onAccept={onAcceptFailPayment} imageicon={icoF}></ErrorMessage>
               <ErrorMessageAuto show={showErrorMessageUpdate} message={receivedMessageUpdate} onAccept={setDocumentAsDelivered} labelButton="Reintentar" imageicon={icoF}></ErrorMessageAuto>

               <ErrorMessage show={showErrorAmountMessage} message="Hubo un error al obtener el monto del documento. Inténtalo de nuevo." onAccept={onAcceptFailAmount} imageicon={icoF}></ErrorMessage>

               <ErrorMessage show={showMessageTGR} message={messageErrorTGR} onAccept={onAcceptTRG} imageicon={icoF}></ErrorMessage>

               <Process show={showWaiting} imageanimation={icon} message={proccessPayMsg} _classprocess={classImgImpresion}></Process>
               <Wainting show={showWaitingGetTGR} imageanimation={iconGetTGR} message={showMessageWaitingGetTGR} _classprocess="imgimpresion"></Wainting>

          </>
     );
};

export default PayMarriage;
