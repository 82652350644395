import React from 'react';
import './GeneralPay.css';
import GeneralPayOptions from './components/DocumentsOptions/GeneralPayOptions';
import GlobalTitleCertificate from '../TitulosCertificados/GlobalTitleCertificate/GlobalTitleCertificate';
import LogotipoRnp from '../Logotipo/LogotipoRnp/LogotipoRnp';


const GeneralPay = ({icon,nameligth,namebold,nameorange,description,detail,purchase, onClickFun, onClickpayWithCode ,amount, setShowPlaces}) => {  

    return (
        <div className='certificateSelector'>
            <div className='contenedorkiosko'>
                <div class="contentlogotipo">
                    <LogotipoRnp></LogotipoRnp>
                    <GlobalTitleCertificate  imgcertificado={icon} nameligth={nameligth} namebold={namebold} nameorange={nameorange} ></GlobalTitleCertificate>
                </div>
                <GeneralPayOptions setShowPlaces={setShowPlaces} nameorange={nameorange} purchase={purchase} description={description} detail={detail} onClickFun={onClickFun} onClickpayWithCode={onClickpayWithCode} amount={amount}>  </GeneralPayOptions>
            </div>
        </div>
    )
};

export default GeneralPay;
