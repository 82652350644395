import React from 'react';
import './Waiting.css';
import CargadorAnimado from '../../components/CargadorAnimado/CargadorAnimado';

const Wainting = ({ show }) => {
   
    if (!show) {
        return null; // Si show es false, no renderiza nada
    }
    return (
        <>
         <div className='waiting-overlay'>
            <div className='waiting-content'>              
                <div className='loading-text'>Por favor, espere...</div>
                <CargadorAnimado>  </CargadorAnimado>
            </div>
        </div>
        </>
    )
};

export default Wainting;