import AWS from "aws-sdk";
import axios from "axios";
import { storeBitacora} from './services'

const configAWSUpdate = () => {
     AWS.config.update({ region: "us-east-1" });
     AWS.config.update({
          accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
          secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
     });
};

// const body = {
//           tgr: tgrObj.tgr,
//           compra: obj.compra,
//           responseVpos:obj.responseVpos
//      };
export const invokeGetPayDocument = async (obj, data_bitacora) => {
     console.log("======================== invokeGetPayDocument  ↓ ");
     const baseUrl = process.env.REACT_APP_API_URL;
     const apiEndpoint = `${baseUrl}/${process.env.REACT_APP_CERT_PAY_DOCUMENT}`; // La URL de tu API
     const storedToken = localStorage.getItem("tk");
 
     console.log("apiEndpoint", apiEndpoint);
     console.log("OBJ", obj);
     console.log("storedToken", storedToken);
 
     const requestBody = { ...obj };
 
     try {
         const response = await axios.post(apiEndpoint, requestBody, {
             headers: {
                 'Content-Type': 'application/json',
                 'Authorization': `Bearer ${storedToken}` // Si necesitas pasar el token en los headers
             }
         });
 
         // Parse the body if it's a JSON string
         const responseBody = typeof response.data.body === 'string' ? JSON.parse(response.data.body) : response.data.body;

         // Registro en bitácora para getPayDocument
         const bitacoraGetPayDocument = {
            getPayDocument: {
                  status: responseBody?.status,
                  data: responseBody,
                  timestamp: new Date().toISOString(),
                  dni: data_bitacora.dni,
            }
        };
        await storeBitacora(JSON.stringify(bitacoraGetPayDocument), data_bitacora.id_bitacora, true, data_bitacora.dni);
 
         console.log("responseInvoke", responseBody);
         console.log("======================== invokeGetPayDocument  ↑  ");
         return responseBody;
     } catch (error) {
        const errorMessage = error.response?.data?.message || error.message || 'Error desconocido';
        const bitacoraGetPayDocument = {
            getPayDocument: {
                  status: false,
                  data: errorMessage,
                  timestamp: new Date().toISOString(),
                  dni: data_bitacora.dni,
            }
        };
        await storeBitacora(JSON.stringify(bitacoraGetPayDocument), data_bitacora.id_bitacora, true, data_bitacora.dni);
         console.error("Error invoking get pay document:", error);
         throw error; // Opcional: Puedes manejar el error según tus necesidades
     }
 };

//                     const body = {
//                          tgr: tgrObj.tgr,
//                          estado: tgrObj.estado,
//                          nombreEnRecibo: obj.compra.name + " " + obj.compra.lastName,
//                          monto: "200", //TODO
//                          authNum: obj.responseVpos.authNum,
//                     };

export const invokePayTGR = async (obj, data_bitacora) => {
     console.log("======================== invokePayTGR  ↓ ");
     const baseUrl = process.env.REACT_APP_API_URL;
     const apiEndpoint = `${baseUrl}/${process.env.REACT_APP_CERT_PAY_TGR}`; // La URL de tu API
     const storedToken = localStorage.getItem("tk");
 
     console.log("apiEndpoint", apiEndpoint);
     console.log("OBJ", obj);
     console.log("storedToken", storedToken);
 
     const requestBody = { ...obj };
 
     try {
         const response = await axios.post(apiEndpoint, requestBody, {
             headers: {
                 'Content-Type': 'application/json',
                 'Authorization': `Bearer ${storedToken}` // Si necesitas pasar el token en los headers
             }
         });
 
         // Parse the body if it's a JSON string
         const responseBody = typeof response.data.body === 'string' ? JSON.parse(response.data.body) : response.data.body;

         // Registro en bitácora para payTGR
         const bitacoraPayTGR = {
            payTGR: {
                status: responseBody?.status,
                data: responseBody,
                timestamp: new Date().toISOString(),
                dni: data_bitacora.dni,
            }
        };
        await storeBitacora(JSON.stringify(bitacoraPayTGR), data_bitacora.id_bitacora, true, data_bitacora.dni);
 
         console.log("responseInvoke", responseBody);
         console.log("======================== invokePayTGR  ↑  ");
         return responseBody;
     } catch (error) {
        const errorMessage = error.response?.data?.message || error.message || 'Error desconocido';
        const bitacoraPayTGR = {
            payTGR: {
                status: false,
                data: errorMessage,
                timestamp: new Date().toISOString(),
                dni: data_bitacora.dni,
            }
        };
        await storeBitacora(JSON.stringify(bitacoraPayTGR), data_bitacora.id_bitacora, true, data_bitacora.dni);
         console.error("Error invoking pay TGR:", error);
         throw error; // Opcional: Puedes manejar el error según tus necesidades
     }
 };
// const body = {
//           tgr: tgrObj.tgr,
//      };
export const invokeValidateTGR = async (obj, data_bitacora) => {
     console.log("======================== invokeValidateTGR  ↓ ");
     const baseUrl = process.env.REACT_APP_API_URL;
     const apiEndpoint = `${baseUrl}/${process.env.REACT_APP_CERT_VALIDATE_TGR}`; // La URL de tu API
     const storedToken = localStorage.getItem("tk");
 
     console.log("apiEndpoint", apiEndpoint);
     console.log("OBJ", obj);
     console.log("storedToken", storedToken);
 
     const requestBody = { ...obj };
 
     try {
         const response = await axios.post(apiEndpoint, requestBody, {
             headers: {
                 'Content-Type': 'application/json',
                 'Authorization': `Bearer ${storedToken}` // Si necesitas pasar el token en los headers
             }
         });
 
         // Parse the body if it's a JSON string
         const responseBody = typeof response.data.body === 'string' ? JSON.parse(response.data.body) : response.data.body;

         // Registro en bitácora para validateTGR
         const bitacoraValidateTGR = {
            validateTGR: {
                status: responseBody?.status,
                data: responseBody,
                timestamp: new Date().toISOString(),
                dni: data_bitacora.dni,
            }
        };
        await storeBitacora(JSON.stringify(bitacoraValidateTGR), data_bitacora.id_bitacora, true, data_bitacora.dni);
 
         console.log("responseInvoke", responseBody);
         console.log("======================== invokeValidateTGR  ↑  ");
         return responseBody;
     } catch (error) {
        const errorMessage = error.response?.data?.message || error.message || 'Error desconocido';
        const bitacoraValidateTGR = {
            validateTGR: {
                status: false,
                data: errorMessage,
                timestamp: new Date().toISOString(),
                dni: data_bitacora.dni,
            }
        };
        await storeBitacora(JSON.stringify(bitacoraValidateTGR), data_bitacora.id_bitacora, true, data_bitacora.dni);
         console.error("Error invoking validate TGR:", error);
         throw error; // Opcional: Puedes manejar el error según tus necesidades
     }
 };

export const invokeCreateTGR = async (obj) => {
     console.log("======================== invokeCreateTGR  ↓ ");
     const baseUrl = process.env.REACT_APP_API_URL;
     const apiEndpoint = `${baseUrl}/${process.env.REACT_APP_CERT_CREATE_TGR}`; // La URL de tu API
     const storedToken = localStorage.getItem("tk");
 
     console.log("apiEndpoint", apiEndpoint);
     console.log("OBJ", obj);
     console.log("storedToken", storedToken);
 
     const requestBody = { ...obj };
 
     try {
         const response = await axios.post(apiEndpoint, requestBody, {
             headers: {
                 'Content-Type': 'application/json',
                 'Authorization': `Bearer ${storedToken}` // Si necesitas pasar el token en los headers
             }
         });
 
         console.log("responseInvoke", response.data);
         console.log("======================== invokeCreateTGR  ↑  ");
         return response.data;
     } catch (error) {
         console.error("Error invoking create TGR:", error);
         throw error; // Opcional: Puedes manejar el error según tus necesidades
     }
 };

 export const invokeCBirth = async (dni, dni_solicitante) => {
     console.log("======================== invokeCBirth  ↓ ");
     const baseUrl = process.env.REACT_APP_API_URL;
     const apiEndpoint = `${baseUrl}/${process.env.REACT_APP_CERT_BIRTH}`; // La URL de tu API
     const storedToken = localStorage.getItem("tk");
 
     console.log("apiEndpoint", apiEndpoint);
     console.log("dni", dni);
     console.log("dni_solicitante", dni_solicitante);
     console.log("storedToken", storedToken);
 
     const requestBody = {
         dni: dni,
         dni_solicitante: dni_solicitante,
     };
 
     try {
         const response = await axios.post(apiEndpoint, requestBody, {
             headers: {
                 'Content-Type': 'application/json',
                 'Authorization': `Bearer ${storedToken}` // Si necesitas pasar el token en los headers
             }
         });
 
         // Parse the body if it's a JSON string
         const responseBody = typeof response.data.body === 'string' ? JSON.parse(response.data.body) : response.data.body;
 
         console.log("responseInvoke", responseBody);
         console.log("======================== invokeCBirth  ↑  ");
         return responseBody;
     } catch (error) {
         console.error("Error invoking C Birth:", error);
         throw error; // Opcional: Puedes manejar el error según tus necesidades
     }
 };
