import React, { useEffect, useState, useRef, useContext } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import './FacialCapture.css';
import Image from '../../../../../../../../assets/images/rnplogotipo.png';
import AppContext from '../../../../../../../../commons/components/AppContext/AppContext';
import WarningMessage from '../../../../../../../../commons/components/WarningMessage/WarningMessage';
import imageiconurl from "../../../../../../../../assets/images/iconalert.png";
import imgWaring from "../../../../../../../../assets/images/forbidden.png";

const FacialCapture = ({ _onClick }) => {
    const globalContext = useContext(AppContext);
    const { _setFacial, _setFinger, showFacialCapture: _show, hideFacialCapture: _hide } = globalContext;
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    const videoRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [selectedCamera, setSelectedCamera] = useState('');
    const [capturedImage, setCapturedImage] = useState(null);
    const [captureMode, setCaptureMode] = useState(false);

    useEffect(() => {
        const storedCamera = localStorage.getItem('selectedCamera');
        if (storedCamera) {
            setSelectedCamera(storedCamera);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        _setFacial(capturedImage);
        console.log("completa facereconigtion", capturedImage);
        _setFinger(null);
    }, [capturedImage]);  // eslint-disable-next-line react-hooks/exhaustive-deps

    const handleShow = async () => {
        try {
            console.log(_show);
            setShowErrorMessage(true);
            setLoading(true);
            const stream = await navigator.mediaDevices.getUserMedia({ video: { deviceId: selectedCamera } });
            videoRef.current.srcObject = stream;
            setLoading(false);
            setCaptureMode(false);
        } catch (error) {
            console.error('Error al acceder a la cámara:', error);
            setLoading(false);
        }
    };

    const onAccept = () => {
        setShowErrorMessage(false);
    };

    const handleHide = () => {
        if (videoRef.current.srcObject) {
            const stream = videoRef.current.srcObject;
            const tracks = stream.getTracks();
            tracks.forEach(track => track.stop());
            videoRef.current.srcObject = null;
        }
        _hide();
        _onClick();
    };

    const handleCapture = () => {
        if (!captureMode) {
            // Capturar la imagen del video
            const canvas = document.createElement('canvas');
            canvas.width = videoRef.current.videoWidth;
            canvas.height = videoRef.current.videoHeight;
            const context = canvas.getContext('2d');
            context.drawImage(
                videoRef.current,
                0,
                0,
                canvas.width,
                canvas.height
            );
            let capturedDataURL = canvas.toDataURL('image/png');

            // Mostrar la imagen en lugar del video
            videoRef.current.srcObject = null;
            videoRef.current.style.display = 'none';
            const capturedImage = document.createElement('img');
            capturedImage.src = capturedDataURL;
            capturedImage.style.width = '523';
            capturedImage.style.height = '528';
            capturedImage.className = 'videoMirror';
            videoRef.current.parentElement.appendChild(capturedImage);
            capturedDataURL = capturedDataURL.replace("data:image/png;base64,", "");
            setCapturedImage(capturedDataURL);
            // Enviar la imagen capturada a la consola en formato base64
            // console.log('Imagen capturada:', capturedDataURL);
        } else {
            // Reactivar el video
            videoRef.current.style.display = 'block';
            const capturedImage = videoRef.current.parentElement.querySelector('img');
            capturedImage.remove();
            // Reactivar el video
            if (videoRef.current.srcObject) {
                const stream = videoRef.current.srcObject;
                const tracks = stream.getTracks();
                tracks.forEach((track) => track.stop());
            }

            // Mostrar el video nuevamente
            navigator.mediaDevices
                .getUserMedia({ video: { deviceId: selectedCamera } })
                .then((stream) => {
                    videoRef.current.srcObject = stream;
                    setLoading(false);
                })
                .catch((error) => {
                    console.error('Error al acceder a la cámara:', error);
                    setLoading(false);
                });
        }

        // Alternar el modo de captura
        setCaptureMode(!captureMode);

        // Llamar a handleHide para cerrar el modal después de capturar
        handleHide();
    };

    const closeModal = () => {
        _hide()
   }

    return (
        <>
            <WarningMessage show={showErrorMessage} message="Para garantizar la máxima precisión en la identificación, te solicitamos amablemente que te quites los lentes o sombreros antes de proceder con la validación facial. Esto nos ayudará a asegurar una experiencia rápida y eficiente." onAccept={onAccept} imageicon={imageiconurl} imgWaring={imgWaring}></WarningMessage>
            <Modal onShow={handleShow} show={_show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header className="mtitleFail">
                    <Modal.Title className="centrarelementos"> <img src={Image} className='logotipocapture' /> <p class="descmodalcapt"> Coloca tu rostro mirando al frente</p></Modal.Title>
                </Modal.Header>
                <Modal.Body className="customModalBody">
                    <center style={{ display: 'flex', alignItems: 'flex-start' }}>
                        <div class="contenedorcaptura facespacecaptura">
                            <video ref={videoRef} autoPlay className={_show ? 'videocapturamirror' : ''} />
                            <div class="backcaptureface"></div>
                        </div>
                        <div class="rigthbtncaptura">
                            <a variant="secondary" class="btnfacial backazul" onClick={handleCapture}>
                                <div class="contenedor alineacolumn"><img src={require('../../../../../../../../assets/images/iconcaptura.png')} className='logotipocapture' />    <p>{!captureMode ? 'Capturar' : 'Volver a capturar'}</p></div>
                            </a>
                            {
                            <a variant="secondary" class="btnfacial backnaranja" onClick={handleHide}>
                                <div class="contenedor alineacolumn">
                                    <img src={require('../../../../../../../../assets/images/iconcheck.png')} className='logotipocapture' />
                                    <p>Confirmar</p>
                                </div>
                            </a>
                             }
                             <button className="close-button-finger" onClick={closeModal}>Atrás</button>
                        </div>
                    </center>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default FacialCapture;
