import React, { useState,useContext} from "react";

import "./CertificateSelector.css";
import CertificateOptions from "./components/CertificateOptions/CertificateOptions";
import LogotipoRnp from "../CertificateSelector/components/LogotipoRnp/LogotipoRnp";
import NameUser from "../DocumentSelector/components/DocumentsOptions/components/NameUser/NameUser";
import Wainting from "../../commons/components/Waiting/Waiting";
import ErrorMessage from "../../commons/components/ErrorMessage/ErrorMessage";
import imageicon from "../../assets/images/iconalert.png"
const CertificateSelector = () => {
     const [showWaiting, setShowWaiting] = useState(false);
     const [showErrorMessage, setShowErrorMessage] = useState(false);
     const onAccept= ()=>{
          setShowErrorMessage(false)
      }
     return (
          <div className="certificateSelector">
               <div className="contenedorkiosko">
                    <LogotipoRnp></LogotipoRnp>
                    <NameUser></NameUser>
                    <CertificateOptions
                         setShowErrorMessage={setShowErrorMessage}
                         setShowWaiting={setShowWaiting}
                    ></CertificateOptions>
                    <Wainting show={showWaiting}></Wainting>
                    <ErrorMessage
                         show={showErrorMessage}
                         message="No se pudo obtener el certificado. Por favor, inténtelo de nuevo."
                         onAccept={onAccept}
                         imageicon={imageicon}
                    ></ErrorMessage>
               </div>
          </div>
     );
};

export default CertificateSelector;
