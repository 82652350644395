import React, {useEffect} from 'react';
import './ErrorMessage.css';

const ErrorMessageAuto = ({ show, message, onAccept, imageicon,labelButton='Aceptar', imgWaring = '' }) => {

  useEffect(() => {
    if (show) {
      // Inicia el temporizador para cerrar automáticamente después de 5 segundos
      const timer = setTimeout(() => {
        handleAcceptClick()
      }, 5000);

      // Limpia el temporizador cuando el componente se desmonta o show cambia a false
      return () => clearTimeout(timer);
    }
  }, [show]);

  const handleAcceptClick = () => {
    onAccept(); // Llama a la función onAccept
  };

  if (!show) {
    return null; // Si show es false, no renderiza nada
  }
  return (
    <div className='error-overlay'>
      <div className='error-content'>
      <img src={imageicon} alt="Alert" className="iconmessage"/>
        <div className='error-message'>{message}</div>
        {
          imgWaring !== '' && (
            <img src={imgWaring} alt="Alert" className="iconmessage"/>
          )
        }
      </div>
    </div>
  );
};

export default ErrorMessageAuto;
