import React, { useEffect, useState } from "react";
import "./TestHuella.css";
import AWS from 'aws-sdk';

const TestHuella = () => {
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        // Configura el SDK de AWS con la región adecuada.
        AWS.config.update({ region: 'us-east-1' });
        AWS.config.update({
          accessKeyId: "AKIAXISXB4XYXD3QWI43",
          secretAccessKey: "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"
        });
      
        const lambda = new AWS.Lambda();

         // Define el cuerpo JSON que deseas enviar a la función Lambda.
         const requestBody = {
          message: 'Mensaje que deseas enviar desde el frontend'
        };


        // Configura los parámetros de la invocación de la función Lambda.
        const lambdaParams = {
          FunctionName: 'kiosko-ws-qa-heartbit', // Reemplaza con el nombre de tu función Lambda.
          InvocationType: 'RequestResponse', // Esto depende de tu caso de uso.
          Payload: JSON.stringify(requestBody), // Envía el cuerpo JSON definido anteriormente.
         
        };

        // Invoca la función Lambda.
        const invokeData = await lambda.invoke(lambdaParams).promise();

        // Parsea la respuesta de la función Lambda (puede variar según tu función).
        const response = JSON.parse(invokeData.Payload);
        console.log("response del invoke", response);
        
        // Actualiza el estado de la aplicación con la respuesta de la función Lambda.
        setResult(response);
      } catch (err) {
        console.error('Error al ejecutar la operación:', err);
        setError(err);
      }
    }

    fetchData();
  }, []);

  return (
    <>
      {error ? (
        <div>Error: {error.message}</div>
      ) : (
        <div>
          <p>Respuesta de la función Lambda:</p>
          <pre>{JSON.stringify(result, null, 2)}</pre>
        </div>
      )}
    </>
  );
};

export default TestHuella;
