import React, { useEffect, useContext, useState, useRef } from 'react';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import './DniInput.css';
import AppContext from '../../../../../../commons/components/AppContext/AppContext';

const DniInput = ({ _setDni }) => {
    const globalContext = useContext(AppContext);
    const [inputValue, setInputValue] = useState('');
    const [isKeyboard, setIsKeyboard] = useState(false);
    const [firstTimeKeyBoard, setFirstTimeKeyBoard] = useState(true);
    const [showErrorMessageDNI, setShowErrorMessageDNI] = useState(false);
    const keyBoardRef = useRef(null);

    useEffect(() => {
        _setDni(inputValue);
    }, [inputValue]); // eslint-disable-line react-hooks/exhaustive-deps

    const enableKeyBoard = () => {
        setIsKeyboard(true);
        setFirstTimeKeyBoard(false);
    };

    const disableKeyBoard = () => {
        setIsKeyboard(false);
    };

    const onChangeInput = (event) => {
        const input = event.target.value;
        setInputValue(input);
        keyBoardRef.current.setInput(input);
    };

    const onChangeKeyBoard = (input) => {
        if (input.length <= 13) {
            setInputValue(input);
            globalContext.setValidateDNI(false);
            if (input.length === 13) {
                globalContext.setValidateDNI(true);
                setShowErrorMessageDNI(false);
                globalContext.handleClickFinger();
            }
        } else {
            return;
        }
        console.log("Input changed", input);
    };

    const onKeyPressKeyBoard = (button) => {
        console.log("Button pressed", button);
        if (button === "{enter}") {
            disableKeyBoard();
            if (!globalContext.validateDNI) {
                setShowErrorMessageDNI(true);
            }
        }
    };

    return (
        <>
            <div className='container1'>
                <div className='identification animated animatedFadeInUp fadeInUp dos'>
                    <input
                        value={inputValue}
                        className='inputDNI'
                        type="text"
                        maxLength={15}
                        pattern="[A-Za-z0-9]*"
                        onFocus={enableKeyBoard}
                        onChange={onChangeInput}
                        placeholder='DNI'
                        readOnly={true} />
                </div>
                {
                    showErrorMessageDNI && (
                        <div className="errorMessageDNI">
                            <span>El Documento Nacional de Identificación debe tener 13 dígitos</span>
                        </div>
                    )
                }
                <div
                    className="keyboard"
                    style={{
                        animation: `${!firstTimeKeyBoard ? (isKeyboard ? "slideIn" : "slideOut") : ""} 0.5s forwards`
                    }}
                >
                    <Keyboard
                        theme={"hg-theme-default myTheme1"}
                        onChange={onChangeKeyBoard}
                        layoutName={"caps"}
                        onKeyPress={onKeyPressKeyBoard}
                        ref={keyBoardRef}
                        layout={{
                            caps: [
                                "1 2 3",
                                "4 5 6",
                                "7 8 9",
                                "0 {bksp}",
                                "{enter}"
                            ]
                        }}
                        display={{
                            '{bksp}': '<',
                            '{enter}': 'Enter'
                        }}
                        buttonTheme={[
                            {
                                class: "hg-enter",
                                buttons: "{enter}"
                            }
                        ]}
                    />
                </div>
            </div>
        </>
    );
};

export default DniInput;
