import React from 'react';
import './CargadorAnimado.css';

const CargadorAnimado = ({ show }) => {
   
    return (
        <>
              
                <div className='cargadoranimado'>
                    <div class="animationcircle one"></div>
                    <div class="animationcircle two"></div>
                    <div class="animationcircle three"></div>
                    <div class="animationcircle four"></div>
                    <div class="animationcircle five"></div>                                                              
                </div>
        </>
    )
};

export default CargadorAnimado;