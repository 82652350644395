import React from 'react';
import './GeneralPayOptiondBtnPdf.css';
// import Image from '../../../../../../assets/images/iconopersona.png';

const GeneralPayOptiondBtnPdf = ({onClickFun}) => {

    const handlePay = (event) =>{
        onClickFun()
    }
    return (
        <>
            <div className='contenedorcertificado animated animatedFadeInUp fadeInUp dos '  onClick={handlePay}>
                    <a className='contbtncerthor btnicon'>
                        <p class="textbtncert"><img className="icondescarga" src={require('../../../../../../../assets/images/paytarjet.png')} alt="Impresion" /> Pagar</p>                          
                    </a>  
            </div>
        </>
    )
};

export default GeneralPayOptiondBtnPdf;