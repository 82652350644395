import React from 'react';
import './DocumentsOptions.css';
import { Container, Row, Col } from 'react-bootstrap';
import BirthCertificate from './components/BirthCertificate/BirthCertificate';
import MarriageCertificate from './components/MarriageCertificate/MarriageCertificate';
//import MarriageCertificate from './components/ReplacementID/ReplacementID';
import DeathCertificate from './components/DeathCertificate/DeathCertificate';
import ReplacementID from './components/ReplacementID/ReplacementID';
//import ReplacementID from './components/ReplacementID/ReplacementID';
import NameUser from './components/NameUser/NameUser';
import Close from './components/Close/Close';


const DocumentsOptions = ({setShowErrorMessage,setShowWaiting}) => {
    return (
        <> 
           <div className="center-screen">
                <Container className="mindocument">
                    <NameUser></NameUser>
                    <div className='animated animatedFadeInUp fadeInUp dos messageSelect'>
                        <h2>Seleccione la transacción que desea realizar</h2>
                    </div>
                    <Row className="align-items-center">
                    <Col>
                        {/* Input */}
                    <BirthCertificate setShowErrorMessage={setShowErrorMessage} setShowWaiting={setShowWaiting}></BirthCertificate>
                    </Col>
                    <Col>
                        {/* Botón 1 */}
                       <MarriageCertificate setShowErrorMessage={setShowErrorMessage} setShowWaiting={setShowWaiting}></MarriageCertificate>
                    </Col>
                    <Col>
                        {/* Botón 2 */}
                       <DeathCertificate  setShowErrorMessage={setShowErrorMessage} setShowWaiting={setShowWaiting}></DeathCertificate>
                    </Col>
                    <Col>
                        {/* Botón 2 */}
                       <ReplacementID  setShowErrorMessage={setShowErrorMessage} setShowWaiting={setShowWaiting}></ReplacementID>
                    </Col>
                    </Row>
                    <Row className="align-items-center">
                    <Col>
                        <Close></Close>
                    </Col>
                    </Row>                    
                </Container>             
                </div>
        </>
    )
};

export default DocumentsOptions;