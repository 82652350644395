import React,{ useState } from 'react';
import "./IdentificationData.css";
import { Container, Row, Col } from "react-bootstrap";
//import BasicButton from '../../../../commons/components/Buttons/BasicButton/BasicButton';
import FingerprintButton from "./components/FingerprintButton/FingerprintButton";
import FacialRecognitionButton from "./components/FacialRecognitionButton/FacialRecognitionButton";
import DniInput from "./components/DniInput/DniInput";

const IdentificationData = ({ _setDni, _onClick}) => {
     return (
          <>
               <div className="center-screen">
                    <Container className="anchokioskocuadrado">
                         <Row className="align-items-center">
                              <Col>
                                   <p className="pasosclass labelTitles animated animatedFadeInUp fadeInUp one">
                                        <strong>Paso 1:</strong> Ingresar el número de Documento Nacional de Identificación
                                   </p>
                                   {/* Input */}
                                   <DniInput _setDni={_setDni}></DniInput>
                                   <p className="pasosclass labelTitles animated animatedFadeInUp fadeInUp one">
                                        <strong>Paso 2:</strong> Seleccione un metódo de identificación biométrico
                                   </p>
                              </Col>
                         </Row>
                         <Row className="align-items-center aligncolumn">
                              <div className="btncontidentifica">
                                   <Col>
                                        {/* Botón 1 */}
                                        <FingerprintButton _onClick={_onClick}></FingerprintButton>
                                   </Col>
                                   <Col>
                                        {/* Botón 2 */}
                                        <FacialRecognitionButton _onClick={_onClick}></FacialRecognitionButton>
                                   </Col>
                              </div>
                         </Row>
                         <Row>
                              <p className="pasosclass labelTitles mensajeimportante animated animatedFadeInUp fadeInUp one">
                                   <strong>Importante:</strong> Debes tener ingresado correctamente el Documento Nacional de Identificación y el método de auténticación biométrico para poder ser validado por el sistema.
                              </p>
                         </Row>
                    </Container>
               </div>
          </>
     );
};

export default IdentificationData;
