import React, {useContext} from "react";
import "./MarriageCertificate.css";
import Image from "../../../../../../assets/images/certmatrimonio.png";
import { useHistory } from "react-router-dom";
import { getListMarriage, storeBitacora } from "../../../../../../Services/services";
import AppContext from "../../../../../../commons/components/AppContext/AppContext";

const MarriageCertificate = ({ setShowErrorMessage, setShowWaiting }) => {
     const history = useHistory();
     const globalContext = useContext(AppContext);

     const handleClick = async () => {
          try {
               globalContext.setMatrimonios('')
               setShowWaiting(true);
               const response = await getListMarriage(globalContext.person.NumInscripcion);

               if(response && response.status){
                    const bitacora = {
                         lst_Matrimonios: {
                             status: true,
                             data: response,
                             timestamp: new Date().toISOString(),
                             dni: globalContext.dni,
                         }
                     };
                     await storeBitacora(JSON.stringify(bitacora), globalContext.id_bitacora, true, globalContext.dni);
                    globalContext.setMatrimonios(response.matrimonios)
               }else{
                    const bitacora = {
                         lst_Matrimonios: {
                             status: false,
                             data: response,
                             timestamp: new Date().toISOString(),
                             dni: globalContext.dni,
                         }
                     };
                     await storeBitacora(JSON.stringify(bitacora), globalContext.id_bitacora, true, globalContext.dni);
               }
               history.push(process.env.REACT_APP_BASE_URL + 'marriageCertificate');
          } catch (error) {
               const bitacora = {
                    lst_Matrimonios: {
                        status: true,
                        data: error,
                        timestamp: new Date().toISOString(),
                        dni: globalContext.dni,
                    }
                };
                await storeBitacora(JSON.stringify(bitacora), globalContext.id_bitacora, true, globalContext.dni);
               setShowWaiting(false);
          }
     };
     return (
          <>
               <div className="CertificateContainer animated animatedFadeInUp fadeInUp dos">
                    <div className="MarriageCertificateBtn" onClick={handleClick}>
                         <img src={Image} className="certbtnimage" />
                         <p class="textbtnselector">
                              Certificado de <span>Matrimonio</span>
                         </p>
                    </div>
               </div>
          </>
     );
};

export default MarriageCertificate;
