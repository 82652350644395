import React , {useContext} from 'react';
import './DescendenciaCertificate.css';
import Image from '../../../../../../assets/images/iconofamilia.png';
import { useHistory } from "react-router-dom";
import {getDecency, storeBitacora} from '../../../../../../Services/services'
import AppContext from "../../../../../../commons/components/AppContext/AppContext";

const DescendenciaCertificate = ({setShowWaiting,setShowErrorMessage}) => {
    const history = useHistory();
    const globalContext = useContext(AppContext);

    function parseJSONResponse(jsonData) {
        // Verificar si el JSON contiene el campo "InscripcionesNac"
        console.log(jsonData)
        const decency=jsonData.InscripcionesNac
        let decencyArr=[]
        if (isObjetJson(decency)) {
                if(decency.ErrorMsg.DescripcionError!=''){
                    console.log('no hay hijos')
                }else{
                    console.log('solo tiene uno hay hijos')
                    decencyArr=ensureArray(decency)
                }
        }else{
            console.log("Arreglo ")
            decencyArr=ensureArray(decency)
        }
    
        return decencyArr;
    
    }
    function ensureArray(jsonData) {
        if (Array.isArray(jsonData)) {
          return jsonData;
        } else if (typeof jsonData === 'object' && jsonData !== null) {
          return [jsonData];
        } else {
          return [];
        }
      }
    function isObjetJson(jsonData) {
        if (Array.isArray(jsonData)) {
          return false;
        } else if (typeof jsonData === 'object' && jsonData !== null) {
          return true        } else {
          return null;
        }
      }
    const handleClick = async () => {
        try {
          setShowWaiting(true);
          const certificateResult = await getDecency(
             globalContext.person.NumInscripcion
        );
        if (certificateResult.errorCode == "00") {
            const bitacora = {
              Qry_HijosInscripcion: {
                  status: true,
                  data: certificateResult,
                  timestamp: new Date().toISOString(),
                  dni: globalContext.dni,
              }
            };
            await storeBitacora(JSON.stringify(bitacora), globalContext.id_bitacora , true ,globalContext.dni)
            setShowWaiting(false);
            
            const hijos=parseJSONResponse(certificateResult.response)
            if(hijos.length>0){
                globalContext.setHijos(hijos)
                console.log('Enviados al contexto')
                history.push(
                   process.env.REACT_APP_BASE_URL + "decencyCertificate"
              );
            }else{
                setShowErrorMessage(true)
            }
            
        } else {
            const bitacora = {
              Qry_HijosInscripcion: {
                  status: false,
                  data: certificateResult,
                  timestamp: new Date().toISOString(),
                  dni: globalContext.dni,
              }
            };
            await storeBitacora(JSON.stringify(bitacora), globalContext.id_bitacora , true ,globalContext.dni)
             setShowErrorMessage(true);
             setShowWaiting(false); 
        }
          
        } catch (error) {
          const errorMessage = error.response?.data?.message || error.message || 'Error desconocido';
          const bitacora = {
            Qry_HijosInscripcion: {
                status: false,
                data: errorMessage,
                timestamp: new Date().toISOString(),
                dni: globalContext.dni,
            }
          };
          await storeBitacora(JSON.stringify(bitacora), globalContext.id_bitacora , true ,globalContext.dni)
          setShowWaiting(false);
          setShowErrorMessage(true);
        }
   };

    return (
        <>
            <div className='contenedorcertificado animated animatedFadeInUp fadeInUp dos'>
                    <a className='contbtncerthor' onClick={handleClick}> 
                        <img src={Image} className=''/>
                        <p class="textbtncert">Descendencia</p>                          
                    </a>  
            </div>
        </>
    )
};

export default DescendenciaCertificate;