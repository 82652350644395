import React, { useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import "./GeneralPayOptionBtnTGR.css";
import Keyboard from "react-simple-keyboard";
import { Container, Row, Col } from "react-bootstrap";

const GeneralPayOptionBtnTGR = ({onClickpayWithCode}) => {
  const [showModal, setShowModal] = useState(false);
  const [tgr, setTGR] = useState();
  const [isKeyboard, setIsKeyboard] = useState(false);
  const [firstTimeKeyBoard, setFirstTimeKeyBoard] = useState(true);
  const [isValidTGR, setIsValidTGR] = useState(false)
  const keyBoardRef = useRef(null);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setTGR('')
    setIsValidTGR(false)
  };

  const enableKeyBoard = () => {
    setIsKeyboard(true);
    setFirstTimeKeyBoard(false);
  };
  const excludeFromLayout = {
    default: [
      "`",
      "@",
      "'",
      ";",
      "=",
      "-",
      ".",
      ",",
      "\\",
      "{tab}",
      "{lock}",
      "{shift}",
      "/",
      "]",
      "[",
      ".com",
    ],
  };

  const display = {
    "{bksp}": "<",
    "{enter}": "↵",
    "{space}": "ESPACIO",
  };

  const onChangeKeyBoard = (input) => {
    if (input.length <= 8) {
      setTGR(input);
    }
    if (input.length >= 8) {
      setIsValidTGR(true)
    }else{
      setIsValidTGR(false)
    }
    console.log("Input changed", input);
  };

  const onKeyPressKeyBoard = (button) => {
    console.log("Button pressed", button);
    if (button === "{enter}") {
      console.log("enter");
    }
  };

  const handlePay = (event) =>{
    onClickpayWithCode(tgr)
  }

  //const isValidTGR = tgr.length === 8;

  return (
    <>
      <div className="contenedorcertificado animated animatedFadeInUp fadeInUp dos">
        <button className="contbtncerthor btnicon" onClick={openModal}>
          <p className="textbtncert">
            <img
              className="icondescarga"
              src={require("../../../../../../../assets/images/insertcode.png")}
              alt="Impresion"
            />
            Usar TGR
          </p>
        </button>
      </div>
      <Modal
        show={showModal}
        onHide={closeModal}
        centered // Esta línea centra el modal verticalmente
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton className="modal-code-tgr">
          <Modal.Title>Validar TGR</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-code-tgr">
          <Col>
            <p className="pasosclass labelTitles p-white">
              Por favor, ingrese el código TGR.
            </p>
            <input
              value={tgr}
              className="inputDNI"
              type="text"
              maxLength={15}
              pattern="[A-Za-z0-9]*"
              onFocus={enableKeyBoard}
              placeholder="Ingrese el TGR"
              readOnly={true}
            /><br/>

            <div className={`btn-enviar-tgr ${!isValidTGR ? "disabled-tgr" : ""}`} onClick={isValidTGR ? handlePay : null}>
              <a className="contbtncerthor-tgr btn-tgr">
                <p class="textbtncert">Enviar</p>
              </a>
            </div>
          </Col>

          <div
            className="keyboard"
            style={{
              animation: `${
                !firstTimeKeyBoard ? (isKeyboard ? "slideIn" : "slideOut") : ""
              } 0.5s forwards`,
            }}
          >
            <Keyboard
              excludeFromLayout={excludeFromLayout}
              theme={"hg-theme-default myTheme1"}
              onChange={onChangeKeyBoard}
              layoutName={"caps"}
              onKeyPress={onKeyPressKeyBoard}
              ref={keyBoardRef}
              display={display}
              layout={{
                caps: [
                    "1 2 3",
                    "4 5 6",
                    "7 8 9",
                    "0 {bksp}",
                    "{enter}"
                ]
            }}
              buttonTheme={[
                {
                  class: "hg-green",
                  buttons: "{enter}",
                },
              ]}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default GeneralPayOptionBtnTGR;
