import React, { useState, useEffect } from 'react';
import "./Alerta.css";
import { useHistory } from "react-router-dom";

const Alerta = () => {
    const alertMessage="Se sobrepaso el tiempo limite de espera, punto temporalmente deshabilitado.";

    return (
        <div className="alerta">
            <div className="contenedorcierre">
                <img className='alertlogotipo' src={require('../../assets/images/rnplogotipowhite.png')} alt="React Logo" />  
                <div className='alertcontent'>
                    <img className='iconoalertrojo' src={require('../../assets/images/iconerroralert.png')} alt="React Logo" />
                    <h3>No hay comunicación </h3>
                    <p>{alertMessage}</p>
                 
                    {/* <button className='error-button'>Volver</button> */}
                </div>
                                  
            </div>
        </div>
    );
};
 
export default Alerta;
