import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import AppContext from "../../commons/components/AppContext/AppContext";
import "./Authentication.css";
import ValidateButton from "./components/ValidateButton/ValidateButton";
import IdentificationData from "./components/IdentificationData/IdentificationData";
import LogotipoRnp from "./components/LogotipoRnp/LogotipoRnp";
import { validateWithFacialRecognition, validateWithFingerRecognition, storeBitacora } from "../../Services/services";
import Wainting from "../../commons/components/Waiting/Waiting";
import WarningMessage from "../../commons/components/WarningMessage/WarningMessage";
import imageiconurl from "../../assets/images/iconalert.png";
import Close from "../../commons/components/Buttons/Close/Close";

const Authentication = () => {
    const globalContext = useContext(AppContext);
    const history = useHistory();
    
    const [showWaiting, setShowWaiting] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [dni, setDni] = useState(null);
    const [btnEnabled, setBtnEnabled] = useState(false);

    const [shouldValidate, setShouldValidate] = useState(false);
    const [bitacoraSent, setBitacoraSent] = useState(false);

    useEffect(() => {
        const btn = dni && (globalContext.facial || globalContext.finger);
        setBtnEnabled(btn);

        if (btn && !bitacoraSent) {
            setShouldValidate(true);
        }
    }, [globalContext.facial, globalContext.finger, dni, bitacoraSent]);

    useEffect(() => {
        const validate = async () => {
            if (!shouldValidate || bitacoraSent) return;

            setShowWaiting(true);
            let result = null;

            if (globalContext.facial) {
                console.log("Validando con facial");
                result = await validateWithFacialRecognition(dni, globalContext.facial);
            } else if (globalContext.finger) {
                console.log("Validando con huella");
                result = await validateWithFingerRecognition(dni, globalContext.finger.replace('data:image/png;base64,', ''));
            }

            setShowWaiting(false);
            handleValidationResult(result);
            setShouldValidate(false);
        };

        validate();
    }, [shouldValidate, bitacoraSent]);

    const handleValidationResult = async (result) => {
        if (bitacoraSent) return;

        const bitacora = {
            [dni]: [
                {
                    Qry_ComparaFacialInscrito: {
                        status: result?.validado === "SI",
                        response: result,
                        timestamp: new Date().toISOString(),
                        dni: dni,
                    }
                }
            ]
        };

        if (result?.validado === "SI") {
            globalContext.setPerson(result.person);
            const response = await storeBitacora(bitacora, 0, true, dni, result.id_customer);
            globalContext.setIdBitacora(response.data.id);
            globalContext.setIdCustomer(result.id_customer);
            globalContext.setDni(dni);
            setBitacoraSent(true);
            history.push(process.env.REACT_APP_BASE_URL + "documentSelector");
        } else {
            if (result) {
                await storeBitacora(bitacora, 0, false, dni, 0);
                setShowErrorMessage(true);
                globalContext._setFinger(null); // Reset finger data to null after failed attempt
                setBitacoraSent(false); 
            }
        }

        globalContext._setFacial('');
        globalContext._setFinger('');
    };

    const handleClick = () => {
        if (btnEnabled && !bitacoraSent) {
            setShouldValidate(true);
        }
    };

    const onAccept = () => {
        setShowErrorMessage(false);
        if (globalContext.finger) {
            globalContext.handleClickModalFacial();
        }
        setBitacoraSent(false);
        setShouldValidate(true); // Re-enable validation on accept
    };

    return (
        <div className="container-app">
            <div className="authentication">
                <div className="contenedorkiosko-auth">
                    <LogotipoRnp />
                    <IdentificationData _onClick={handleClick} _setDni={setDni} />
                    <ValidateButton text="VALIDAR" _onClick={handleClick} _enabled={btnEnabled} _className="btnkioskoactive animated animatedFadeInUp fadeInUp dos" size="6em" />
                    <Close />
                    <Wainting show={showWaiting} />
                    <WarningMessage show={showErrorMessage} message="No se pudo encontrar la información del ciudadano. Por favor, inténtelo de nuevo." onAccept={onAccept} imageicon={imageiconurl} ayuda={true} />
                </div>
            </div>
        </div>
    );
};

export default Authentication;
