import React , {useState}from 'react';
import TitularCertificate from './components/DocumentsOptions/components/TitularCertificate/TitularCertificate';
import GlobalTitleCertificate from '../../commons/components/TitulosCertificados/GlobalTitleCertificate/GlobalTitleCertificate';
import ImageNacimiento from '../../assets/images/certnacimiento.png';
import './DescendenciaCertificate.css';
import DocumentsOptions from './components/DocumentsOptions/DescendenciaCertificateOptions';
import LogotipoRnp from '../../commons/components/Logotipo/LogotipoRnp/LogotipoRnp';
import Wainting from '../../commons/components/Waiting/Waiting';
import ErrorMessage from '../../commons/components/ErrorMessage/ErrorMessage';
import imageicon from "../../assets/images/iconalert.png"

const DescendenciaCertificate = () => {  
    const [showWaiting, setShowWaiting] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    const onAccept= ()=>{
        setShowErrorMessage(false)
    }
    return (
        <div className='certificateSelector'>
            <div className='contenedorkiosko'>
                <div class="contentlogotipo">
                    <LogotipoRnp></LogotipoRnp>
                    <GlobalTitleCertificate imgcertificado={ImageNacimiento} nameligth="Certificado de" namebold="Nacimiento" nameorange="Descendencia" ></GlobalTitleCertificate>
                </div>
                <DocumentsOptions setShowErrorMessage={setShowErrorMessage} setShowWaiting={setShowWaiting} showErrorMessage={showErrorMessage}>  </DocumentsOptions>
                <Wainting show={showWaiting}></Wainting>
                <ErrorMessage imageicon={imageicon}  show={showErrorMessage} message="No se pudo obtener el certificado. Por favor, inténtelo de nuevo." onAccept={onAccept} ></ErrorMessage>
            
          
            </div>
        </div>
    )
};

export default DescendenciaCertificate;
