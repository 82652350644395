import React, {useState,useContext,useEffect} from 'react';
import './SearchDeathOptions.css';
import { Container, Row, Col } from 'react-bootstrap';
import ImpresionPdf from './components/BtnContinuarDeath/BtnContinuarDeath';
import NameUser from "../../../../commons/components/UserNames/NameUser/NameUser";
import InputValida from './components/InputDeath/InputDeath';
import IrMenu from '../../../../commons/components/Buttons/IrMenu/IrMenu';
import Close from '../../../../commons/components/Buttons/Close/Close';
import Wainting from '../../../../commons/components/Waiting/Waiting';
import ErrorMessage from '../../../../commons/components/ErrorMessage/ErrorMessage';

import imageiconurl from "../../../../assets/images/iconalert.png";
import AppContext from "../../../../commons/components/AppContext/AppContext";
import { storeBitacora } from "../../../../Services/services";


const SearchDeathOptions = () => {
    const [showWait, setShowWait] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const globalContext = useContext(AppContext);
    const id_bitacora = globalContext.id_bitacora
    const dni_user = globalContext.dni
    
    const sendBitacora = async (dni, id_bitacora) => {
        const bitacora = {
            searchDeath: {
                 status: true,
                 data: "Buscar certificado de defuncion",
                 timestamp: new Date().toISOString(),
                 dni: dni,
            }
        };
        await storeBitacora(JSON.stringify(bitacora), id_bitacora , true ,dni)
    }

    useEffect(() => {
        console.log('dni', dni_user)
        if(dni_user){
            sendBitacora(dni_user, id_bitacora)
        }
    }, [dni_user])

    
    const [dni, setDni] = useState(null);
    const onAccept = () =>{
        setErrorMessage(false)
    }
    return (
        <> 
           <div className="center-screen">
                <Container className="mincertificado paddingdeath">
                    <div className="paddingdeathuser"><NameUser></NameUser></div>
                    <Row className="align-items-center">
                    <Col className="izqcertcontfloat">
                        <InputValida  _setDni={setDni}></InputValida>
                    </Col>
                    <Col className="dercertcontfloat">
                        <ImpresionPdf setErrorMessage={setErrorMessage} setShowWait={setShowWait} dni={dni}></ImpresionPdf>
                    </Col>                    
                    </Row>
                    <Row className="align-items-center">
                    <Col>
                        <div className="alignbtnfooter">
                            <div className="center">                                
                                <IrMenu></IrMenu>
                                <Close></Close>
                            </div>
                        </div>
                    </Col>                 
                    </Row>                    
                </Container>
                <Wainting show={showWait} ></Wainting>
                <ErrorMessage show={errorMessage} message="No hay registro de defunción, valide los datos ingresados." onAccept={onAccept} imageicon={imageiconurl}></ErrorMessage>
                </div>
        </>
    )
};

export default SearchDeathOptions;