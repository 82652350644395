import React, { useEffect, useState, useContext } from 'react';
import './Places.css';
import { getListPlacesPickup, storeBitacora } from '../../../Services/services';
import CargadorAnimado from '../CargadorAnimado/CargadorAnimado';
import AppContext from "../../../commons/components/AppContext/AppContext";

const Places = ({ show, setCodigoEntrega, setShowPlaces, CodigoEntrega }) => {
  const [places, setPlaces] = useState([]);
  const [style, setStyle] = useState([]);
  const [loading, setLoading] = useState(true); // Estado para el indicador de carga
  const globalContext = useContext(AppContext);

  useEffect(() => {
    async function fetchData() {
      try {
        const placesData = await getListPlacesPickup();
        const placesParse = JSON.parse(placesData);
        const places = placesParse.lst_LugaresEntregaDNIResponse.infoUbicacionesEntrega;
        const placesArray = Array.isArray(places) ? places : [places];
        const bitacora = {
          lst_LugaresEntregaDNI: {
              status: true,
              data: placesArray,
              timestamp: new Date().toISOString(),
              dni: globalContext.dni,
          }
      };
      await storeBitacora(JSON.stringify(bitacora), globalContext.id_bitacora, true, globalContext.dni);
        const uniquePlaces = placesArray.filter((item, index, array) => {
          const currentCode = item.infoUbicaciones.Codigolugarentrega;
          return array.findIndex((place) => place.infoUbicaciones.Codigolugarentrega === currentCode) === index;
        });
        const diferencia = uniquePlaces.length - 5;
        const margin = uniquePlaces.length <= 5 ? 0 : 150 * diferencia;

        setStyle({ marginTop: margin + 'px' });
        setPlaces(uniquePlaces);
        setLoading(false); // Marcar como cargado cuando se obtienen los datos
      } catch (error) {
        const bitacora = {
          lst_LugaresEntregaDNI: {
              status: false,
              data: error,
              timestamp: new Date().toISOString(),
              dni: globalContext.dni,
          }
      };
      await storeBitacora(JSON.stringify(bitacora), globalContext.id_bitacora, true, globalContext.dni);
        console.error('Error al obtener la lista de lugares de recogida:', error);
        setLoading(false); // Marcar como cargado incluso si hay un error
      }
    }

    fetchData();
  }, []);

  const savePlaceCode = (codigo) => {
    setCodigoEntrega(codigo);
    setShowPlaces(false);
    console.log(codigo);
  };

  if (!show) {
    return null;
  }

  return (
    <div className='overlay'>
      <div className='content-places' style={style}>
        <button onClick={() => setShowPlaces(false)} className='close-button'>
          X
        </button>
        {loading ? (
          // Mostrar el indicador de carga mientras se obtienen los datos
          <div>
            <h3>Cargando ...</h3>
            <CargadorAnimado></CargadorAnimado>
          </div>
        ) : (
          // Mostrar la lista de lugares cuando los datos están disponibles
          <div>
            <h3>Por favor, seleccione el lugar donde se le entregará su DNI.</h3>
            <ul>
              {places.length === 0 ? (
                <li
                  className='seleccionado'
                  key='default'
                  onClick={() => savePlaceCode('0801OFCPRIC')}
                >
                  <div className='info-lugar-entrega'>
                    <p className='descripcion-lugar-entrega'>OFICINAS PRINCIPALES</p>
                    <p className='direccion-lugar-entrega'>Tegucigalpa - Torre Futura</p>
                  </div>
                </li>,

                <li
                  className='seleccionado'
                  key='default'
                  onClick={() => savePlaceCode('0501PLAZAUNO')}
                >
                  <div className='info-lugar-entrega'>
                    <p className='descripcion-lugar-entrega'>PLAZA UNO</p>
                    <p className='direccion-lugar-entrega'>San Pedro Sula - Salida a la Lima</p>
                  </div>
                </li>
              ) : (
                places.map((item, index) => {
                  const place = item.infoUbicaciones;
                  let classCodigoEntrega = '';
                  if (place.Codigolugarentrega === CodigoEntrega) {
                    classCodigoEntrega = 'seleccionado';
                  }
                  return (
                    <li
                      className={classCodigoEntrega}
                      key={index}
                      onClick={() => savePlaceCode(place.Codigolugarentrega)}
                    >
                      <div className='info-lugar-entrega'>
                        <p className='descripcion-lugar-entrega'>{place.Descripcionlugarentrega}</p>
                        <p className='direccion-lugar-entrega'>{place.Direccionlugarentrega}</p>
                      </div>
                    </li>
                  );
                })
              )}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default Places;