import React, {useContext,useEffect} from 'react';
import './CertificateOptions.css';
import { Container, Row, Col } from 'react-bootstrap';
import GlobalTitleCertificate from '../../../../commons/components/TitulosCertificados/GlobalTitleCertificateVertical/GlobalTitleCertificateVertical';
import ImageNacimiento from '../../../../assets/images/certnacimiento.png';
import PersonalCertificate from './components/PersonalCertificate/PersonalCertificate';
import DescendenciaCertificate from './components/DescendenciaCertificate/DescendenciaCertificate';
import IrMenu from '../../../../commons/components/Buttons/IrMenu/IrMenu';
import Close from '../../../../commons/components/Buttons/Close/Close';
import AppContext from "../../../../commons/components/AppContext/AppContext";
import { storeBitacora } from "../../../../Services/services";

const sendBitacora = async (dni, id_bitacora) => {
    const bitacora = {
        certificateSelector: {
             status: true,
             data: "Certificado de Nacimiento",
             timestamp: new Date().toISOString(),
             dni: dni,
        }
    };
    await storeBitacora(JSON.stringify(bitacora), id_bitacora , true ,dni)
}

const CertificateOptions = ({setShowErrorMessage,setShowWaiting}) =>{
    const globalContext = useContext(AppContext);
    const id_bitacora = globalContext.id_bitacora
    const dni = globalContext.dni
    useEffect(() => {
        console.log('dni', dni)
        if(dni){
            sendBitacora(dni, id_bitacora)
        }
    }, [dni])
    return (
        <> 
           <div className="center-screen">
                <Container className="mincertificado">
                    <Row className="align-items-center">
                    <Col className="izqcertcontfloat">
                        <GlobalTitleCertificate imgcertificado={ImageNacimiento} nameligth="Certificado de" namebold="" nameorange="Nacimiento" ></GlobalTitleCertificate>
                    </Col>
                    <Col className="dercertcontfloat">
                        <PersonalCertificate setShowErrorMessage={setShowErrorMessage} setShowWaiting={setShowWaiting}></PersonalCertificate>
                        <DescendenciaCertificate  setShowErrorMessage={setShowErrorMessage} setShowWaiting={setShowWaiting}></DescendenciaCertificate>
                    </Col>                    
                    </Row>
                    <Row className="align-items-center">
                    <Col>
                        <div className="alignbtnfooter">
                            <div className="center">                                
                                <IrMenu></IrMenu>
                                <Close></Close>
                            </div>
                        </div>
                    </Col>                 
                    </Row>                    
                </Container>
                </div>
        </>
    )
};

export default CertificateOptions;