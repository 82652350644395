import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import './WarningMessage.css';

const WarningMessage = ({ show, message, onAccept, imageicon, labelButton = 'Aceptar', imgWaring = '' }) => {
  if (!show) {
    return null; // Si show es false, no renderiza nada
  }

  return (
    <div className='error-overlay'>
      <div className='error-content'>
        <img src={imageicon} alt="Alert" className="iconmessage" />
        <div className='error-message'>{message}</div>
        {imgWaring !== '' && (
          <img src={imgWaring} alt="Alert" className="iconmessage" />
        )}
        <div className="button-container1-error">
          <button className="error-button" onClick={onAccept}>{labelButton}</button>
        </div>
      </div>
    </div>
  );
};

export default WarningMessage;
