import React, {useContext} from 'react';
import './NameUser.css';
import AppContext from '../../../../../../commons/components/AppContext/AppContext';



const NameUser = ({ _className,text, size }) => {

    const globalContext = useContext(AppContext);

    return (
        <>
            <div className='animated animatedFadeInUp fadeInUp dos'>
            <h2 className="usernameid">Bienvenido <span>{[globalContext.person?.Nombres, globalContext.person?.PrimerApellido, globalContext.person?.SegundoApellido].filter(Boolean).join(" ")}</span></h2>
            </div>           
        </>
    )
};

export default NameUser;