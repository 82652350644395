import React, { useEffect, useContext,useState,useRef } from 'react';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import './InputDeath.css';
import Image from '../../../../../../assets/images/iconopersona.png';

const InputDeath = ({_setDni}) => {

    const [inputValue,setInputValue]=useState('');
    const [isKeyboard, setIsKeyboard] = useState(false);
    const [firstTimeKeyBoard, setFirstTimeKeyBoard] = useState(true);
    const keyBoardRef = useRef(null);

    useEffect(() => {
        _setDni(inputValue)
    }, [inputValue]);// eslint-disable-line react-hooks/exhaustive-deps
    const excludeFromLayout= {
        default: ["`","@","'",";","=","-",".",",","\\","{tab}","{lock}","{shift}","/","]","[", ".com"]
    }   
    const display= {
        '{bksp}': '<',
        '{enter}': '↵',
        '{space}':"ESPACIO"
    }
    const enableKeyBoard=()=>{
        setIsKeyboard(true)
        setFirstTimeKeyBoard(false)
    }   
    const disableKeyBoard=()=>{
        setIsKeyboard(false)
    }

    const onChangeInput = (event) => {
        const input = event.target.value;
        setInputValue(input);
        keyBoardRef.setInput(input);
      };
    //_keyBoardRef
    const onChangeKeyBoard = (input) => {
        console.log("Input changed", input);
        if(input.length <= 13){
            setInputValue(input);
        }
      }
    
    const onKeyPressKeyBoard = (button) => {
        console.log("Button pressed", button);
        if (button === "{enter}"){
            disableKeyBoard()
        }

    }

    return (
        <>
            <div className='continputvalide animated animatedFadeInUp fadeInUp dos'>
                <h3><strong>DNI</strong> DIFUNTO</h3>
                <input 
                    value={inputValue}
                    className='inputDNI' 
                    type="text" 
                    maxLength={13}
                    pattern="[A-Za-z0-9]*"
                    onFocus={enableKeyBoard}
                    onChange={onChangeInput}
                    placeholder='DNI'
                    readOnly={true}/>
            </div>
            <div 
                className="keyboard"
                style={{
                    animation: `${!firstTimeKeyBoard?(isKeyboard ? "slideIn" : "slideOut"):""} 0.5s forwards`
                }}
            >

                <Keyboard
                    excludeFromLayout={excludeFromLayout}
                    theme={"hg-theme-default myTheme1"}
                    onChange={onChangeKeyBoard}
                    layoutName={"caps"}
                    onKeyPress={onKeyPressKeyBoard}
                    ref={keyBoardRef}
                    layout={{
                        caps: [
                            "1 2 3",
                            "4 5 6",
                            "7 8 9",
                            "0 {bksp}",
                            "{enter}"
                        ]
                    }}
                    display={{
                        '{bksp}': '<',
                        '{enter}': 'Enter'
                    }}
                    buttonTheme={[
                        {
                            class: "hg-enter",
                            buttons: "{enter}"
                        }
                    ]}
                    
                
                />
                
            </div>            
        </>
    )
};

export default InputDeath;