import React from 'react';
import { useHistory } from "react-router-dom";

import './ValidateButton.css';
import BasicButton from '../../../../commons/components/Buttons/BasicButton/BasicButton';

const ValidateButton = ({ _className,text, size,_onClick,_enabled }) => {

    const history = useHistory();

    // const handleClick = () => {
    //     console.log('goto -> Step1');
    //     history.push(process.env.REACT_APP_BASE_URL + 'documentSelector');
    // };
    return (
        <>
            <BasicButton _onClick={_onClick} _enabled={_enabled} _className={_className} text={text} size={size}></BasicButton>
        </>
    )
};

export default ValidateButton;