import React, { useEffect, useState, useRef, useContext } from "react";
import { Modal } from "react-bootstrap";
import "./FingerprintCapture.css";
import logo from "../../../../../../../../assets/images/rnplogotipo.png";
import AppContext from "../../../../../../../../commons/components/AppContext/AppContext";
import Webcam from "react-webcam";

const FingerprintCapture = ({_onClick}) => {
     const globalContext = useContext(AppContext);
     const {_setFacial, _setFinger, showFingerCaputre: _show , hideFingerCaputre: _hide} = globalContext;
     const webcamRef = useRef();
     const [selectedDeviceId, setSelectedDeviceId] = useState("");
     const [capturedImage, setCapturedImage] = useState();

     const [motionDetected, setMotionDetected] = useState(false);
     const [countdown, setCountdown] = useState(5);
     const cameraWidth = 900;
     const cameraHeight = 506;

     useEffect(() => {
          //test.startCapture()
          const deviceIdFinger = localStorage.getItem("selectedFingerprintDevice");
          if (deviceIdFinger) {
               setSelectedDeviceId(deviceIdFinger);
          }
     }, []); // eslint-disable-next-line react-hooks/exhaustive-deps

     const handleHide = () => {
          const imgBase64 = localStorage.getItem('huellaBase64');
          setCapturedImage(imgBase64);
          _hide();
          test.stopCapture()
          _onClick()
     };

     const handleOtherCapture = () => {
          console.log("Se reactiva toma de huella");
     };

     const closeModal = () => {
          _hide()
     }

     useEffect(() => {
          _setFinger(capturedImage);
          _setFacial(null);
     }, [capturedImage]);

     return (
          <>
               <Modal show={_show} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header className="mtitleFail">
                         <center>
                              <Modal.Title>
                                   {" "}
                                   <img src={logo} className="logotipocapture" /> <p className="descmodalcapt"> Coloca cualquier dedo en el lector</p>
                                   {/* {motionDetected && <div className="countdown">Huella detectada</div>} */}
                              </Modal.Title>
                         </center>
                    </Modal.Header>
                    <Modal.Body className="customModalBody">
                         <center style={{ display: "flex", alignItems: "flex-start" }}>
                              <div className={`fingerContainer ${capturedImage ? "haveImage" : "rotateFinger"}`}>
                                   <img id="capturedImage" width={cameraWidth} height={cameraHeight} className="captured-toSend" />
                              </div>
                              <div className="rigthbtncaptura fingerbtnmodify">

                                   {/* {!motionDetected && countdown >= 0 && (
                                        <button className="btnCaptureDisabled backgris" >
                                             <div className="contenedor">
                                                  <i className="fa-regular fa-image"></i>

                                                  <p>Volver a tomar huella</p>
                                             </div>
                                        </button>
                                   )} */}
                                   {!motionDetected && countdown <= 0 && (
                                        <button className="btnfinger backazul" onClick={handleOtherCapture}>
                                             <div className="contenedor">
                                                  <img src={require("../../../../../../../../assets/images/iconcaptura.png")} className="logotipocapture" />
                                                  <p>Volver a tomar huella</p>
                                             </div>
                                        </button>
                                   )}
                                   {countdown <= 5 && countdown > 0 && (
                                        <button className="btnCaptureDisabled backgris">
                                             <div className="contenedor">
                                                  <img src={require("../../../../../../../../assets/images/huellaAnimacion.gif")} className="huellaanimaset" />
                                                  <p className="contador">
                                                       {countdown < 5 && countdown > 0 && (
                                                            <strong>
                                                                 Capturando
                                                                 <span>{countdown + 1}</span>
                                                            </strong>
                                                       )}
                                                  </p>
                                                  <p>{countdown == 5 && <span> --- </span>}</p>
                                             </div>
                                        </button>
                                   )}

                                   {
                                        
                                        <button className="btnfinger backnaranja" onClick={handleHide}>
                                        <div className="contenedor alineacolumn">
                                             <img src={require("../../../../../../../../assets/images/iconcheck.png")} className="logotipocapture" /> <p>CONFIRMAR</p>
                                        </div>
                                   </button>
                                         
                                   }
                                   <button className="close-button-finger" onClick={closeModal}>Atrás</button>
                              </div>
                         </center>
                    </Modal.Body>
               </Modal>
          </>
     );
};

export default FingerprintCapture;
