import React from 'react';
import { FaHome } from "react-icons/fa";
import './TopBar.css';

const TopBar = () => {
    const handleHomeClick = () => {
        window.location.href = '/'; // Redirige a la página de inicio
    };

    return (
        <div>
            <FaHome className="homeIcon" onClick={handleHomeClick} />
        </div>
    );
};

export default TopBar;
