import React, { useEffect } from 'react';
import "./Cierre.css";
import { useHistory } from "react-router-dom";

const Cierre = () => {
     const history = useHistory();

    useEffect(() => {
        const timer = setTimeout(() => {
            history.push(process.env.REACT_APP_BASE_URL + "Welcome");
        }, 8000); // 8000 milisegundos = 8 segundos

        return () => {
            clearTimeout(timer); // Limpia el temporizador al desmontar el componente
        };
    }, []);

    return (
        <div className="cierre">
            <div className="contenedorcierre">
                <img className='imagecierre' src={require('../../assets/images/logotipoCierre.gif')} alt="React Logo" />                    
            </div>
        </div>
    );
};
 
export default Cierre;
