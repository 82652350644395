import React, { useEffect, useState, useRef, useContext } from 'react';
import { Modal, Button } from 'react-bootstrap';
import './ErrorMessage.css';
import Keyboard from "react-simple-keyboard";
import AppContext from "../AppContext/AppContext";
import WarningMessage from '../WarningMessage/WarningMessage';
import imageiconurl from "../../../assets/images/iconalert.png";
import axios from "axios";
import { useHistory } from "react-router-dom";

const ErrorMessage = ({ show, message, onAccept, imageicon, labelButton = 'Aceptar', imgWaring = ''}) => {
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [firstTimeKeyBoard, setFirstTimeKeyBoard] = useState(true);
  const [isKeyboard, setIsKeyboard] = useState(false);
  const [number, setNumber] = useState("");
  const [isValidNumber, setIsValidNumber] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const keyBoardRef = useRef(null);
  const globalContext = useContext(AppContext);
  const history = useHistory();

  if (!show) {
    return null; // Si show es false, no renderiza nada
  }

  const handleHelpClick = () => {
    setNumber('')
    setShowHelpModal(true);
  };

  const enableKeyBoard = () => {
    setIsKeyboard(true);
    setFirstTimeKeyBoard(false);
  };

  const handleSendPhoneNumber = async () => {
    console.log('Número de teléfono enviado:', number);
    try {
      const baseUrl = process.env.REACT_APP_API_URL;
      const token = localStorage.getItem("tk");
      const data = { phone: number, id_bitacora: globalContext.id_bitacora, id_customer: globalContext.id_customer }; // Datos que deseas enviar al endpoint

      const response = await axios.post(
        `${baseUrl}/help-user`,
        data,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );

      console.log('Response:', response.data);
      handleCloseModal();
      setShowErrorMessage(true);
      setNumber("");
      return response.data;
    } catch (error) {
      console.error('Error storing bitacora in help-user:', error);
    }
  };

  const onAcceptWarning = () => {
    history.push(process.env.REACT_APP_BASE_URL + "Welcome");
  };

  const handleCloseModal = () => {
    setShowHelpModal(false);
  };

  const excludeFromLayout = {
    default: [
      "`",
      "@",
      "'",
      ";",
      "=",
      "-",
      ".",
      ",",
      "\\",
      "{tab}",
      "{lock}",
      "{shift}",
      "/",
      "]",
      "[",
      ".com",
    ],
  };

  const display = {
    "{bksp}": "<",
    "{enter}": "↵",
    "{space}": "ESPACIO",
  };

  const onChangeKeyBoard = (input) => {
    // Allow only digits and ensure length is exactly 8
    if (/^\d*$/.test(input) && input.length <= 8) {
      setNumber(input);
      setIsValidNumber(input.length === 8);
    }
    console.log("Input changed", input);
  };

  const onKeyPressKeyBoard = (button) => {
    console.log("Button pressed", button);
    if (button === "{enter}") {
      console.log("enter");
    }
  };

  return (
    <div className='error-overlay'>
      <div className='error-content'>
        <img src={imageicon} alt="Alert" className="iconmessage" />
        <div className='error-message'>{message}</div>
        {imgWaring !== '' && (
          <img src={imgWaring} alt="Alert" className="iconmessage" />
        )}
        <div className="button-container1-error">
          <button className="error-button" onClick={onAccept}>{labelButton}</button>
          <button className="ayuda-button" onClick={handleHelpClick}>Ayuda</button>
        </div>
      </div>

      <Modal
        show={showHelpModal}
        onHide={handleCloseModal}
        className="custom-modal"
        backdropClassName="custom-modal-backdrop"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="modal-title">Ayuda</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="tel"
            className="phone-input"
            placeholder="Ingresa tu número de celular"
            value={number}
            onFocus={enableKeyBoard}
            pattern="[0-9]*"
            maxLength={8}
          />
          <p className='span-contacto'>Por favor, ingrese su número de teléfono para que nuestro equipo de soporte pueda contactarlo.</p>
          <div
            className="keyboard"
            style={{
              animation: `${
                !firstTimeKeyBoard ? (isKeyboard ? "slideIn" : "slideOut") : ""
              } 0.5s forwards`,
            }}
          >
            <Keyboard
              excludeFromLayout={excludeFromLayout}
              theme={"hg-theme-default myTheme1"}
              onChange={onChangeKeyBoard}
              layoutName={"caps"}
              onKeyPress={onKeyPressKeyBoard}
              ref={keyBoardRef}
              layout={{
                caps: [
                  "1 2 3",
                  "4 5 6",
                  "7 8 9",
                  "0 {bksp}",
                  "{enter}"
              ]
              }}
              display={{
                '{bksp}': '<',
                '{enter}': 'Enter'
            }}
              buttonTheme={[
                {
                  class: "hg-green",
                  buttons: "{enter}",
                },
              ]}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleSendPhoneNumber} disabled={!isValidNumber}>
            Enviar
          </Button>
        </Modal.Footer>
      </Modal>
      <WarningMessage show={showErrorMessage} message="Uno de nuestros representantes se pondrá en contacto con usted, para proporcionarle la asistencia que necesita." onAccept={onAcceptWarning} imageicon={imageiconurl} imgWaring={imgWaring}></WarningMessage>
    </div>
  );
};

export default ErrorMessage;
