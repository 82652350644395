import React from 'react';
import './PreviewMarriageCertPdf.css';
import Image from '../../../../../../assets/images/rnppdfpreview.png';

const PreviewMarriageCertPdf = ({_onClick}) => {
    return (
        <>
            <div className='contentpdfpreview animated animatedFadeInUp fadeInUp dos'>
                <div className='certipreview' onClick={_onClick}>
                    <img src={Image} className='certbtnimage btnbordernaranja'/>
                </div>
               
            </div>
        </>
    )
};

export default PreviewMarriageCertPdf;