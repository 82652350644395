import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import AppContext from "../../commons/components/AppContext/AppContext";
import "./Welcome.css";
import StartButton from "./components/StartButton/StartButton";
import LogotipoRnp from "./components/LogotipoRnp/LogotipoRnp";

const Welcome = () => {
    const globalContext = useContext(AppContext);
    const history = useHistory();

    const [isVideoActive, setIsVideoActive] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');
    const [audioUrl, setAudioUrl] = useState(''); // Estado para el audio
    const [videoError, setVideoError] = useState(false);

    const clearLocalStorage = () => {
        if (localStorage.getItem("selectedCamera")) {
            localStorage.removeItem("selectedCamera");
        }
        if (localStorage.getItem("selectedFingerprintDevice")) {
            localStorage.removeItem("selectedFingerprintDevice");
        }
        if (localStorage.getItem("tk")) {
            localStorage.removeItem("tk");
        }
    };

    useEffect(() => {
        let timeout;

        // Función para iniciar el temporizador de inactividad
        const startInactivityTimer = () => {
            timeout = setTimeout(() => {
                // Intentar descargar el video
                fetch(`${process.env.REACT_APP_API_URL}/download/publicidad.mp4`)
                    .then(response => {
                        if (!response.ok) {
                            throw new Error('No se pudo descargar el video');
                        }
                        return response.blob();
                    })
                    .then(blob => {
                        const url = URL.createObjectURL(blob);
                        setVideoUrl(url);
                        setIsVideoActive(true); // Mostrar el video solo si la descarga fue exitosa
                    })
                    .catch(error => {
                        console.error('Error al obtener el video:', error);
                        setVideoError(true); // Indicar que hubo un error
                        setIsVideoActive(false); // Asegurarse de que no se muestre el video
                    });
            }, 5000); // 5 segundos de inactividad
        };

        // Descargar el audio al montar el componente
        const downloadAudio = () => {
            fetch(`${process.env.REACT_APP_API_URL}/download/inicio.mp3`)
                .then(response => response.blob())
                .then(blob => {
                    const url = URL.createObjectURL(blob);
                    setAudioUrl(url);
                })
                .catch(error => console.error('Error al obtener el audio:', error));
        };

        // Función para reiniciar el temporizador de inactividad y ocultar el video
        const resetTimeout = () => {
            clearTimeout(timeout);
            setIsVideoActive(false); // Ocultar video
            startInactivityTimer();  // Reiniciar el temporizador
        };

        // Función para manejar el evento de teclado
        const handleKeyPress = (e) => {
            // Verificar si se presionó CTRL + SHIFT + Q
            if (e.ctrlKey && e.shiftKey && e.key === "Q") {
                clearLocalStorage();
                history.push(process.env.REACT_APP_BASE_URL);
            }
        };

        // Descargar el audio al montar el componente
        downloadAudio();

        // Agregar el escuchador de eventos para la inactividad
        window.addEventListener("mousemove", resetTimeout);
        window.addEventListener("click", resetTimeout);
        window.addEventListener("keydown", resetTimeout);

        // Agregar el escuchador de eventos para el teclado
        document.addEventListener("keydown", handleKeyPress);

        // Iniciar el temporizador de inactividad por primera vez
        startInactivityTimer();

        // Limpiar listeners y timeout cuando el componente se desmonta
        return () => {
            clearTimeout(timeout);
            window.removeEventListener("mousemove", resetTimeout);
            window.removeEventListener("click", resetTimeout);
            window.removeEventListener("keydown", resetTimeout);
            document.removeEventListener("keydown", handleKeyPress);
        };
    }, [history]);

    return (
        <div className="welcome">
            <div className="contenedorkiosko-welcome">
                {isVideoActive && !videoError ? (
                    <video
                        width="100%"
                        height="100%"
                        autoPlay
                        loop
                        src={videoUrl}
                        type="video/mp4"
                    >
                        Tu navegador no soporta la etiqueta de video.
                    </video>
                ) : (
                    <div>
                        <LogotipoRnp />
                        <StartButton text="COMENCEMOS" _className="btnkioskoactive animated animatedFadeInUp fadeInUp six" size="6em" />
                        {audioUrl && (
                            <audio autoPlay>
                                <source src={audioUrl} type="audio/mp3" />
                                Tu navegador no soporta la etiqueta de audio.
                            </audio>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Welcome;