import React,{useState,useContext} from 'react';
import './BirthCertificate.css';
import Image from '../../../../../../assets/images/certnacimiento.png';
import { useHistory } from "react-router-dom";

import AppContext from '../../../../../../commons/components/AppContext/AppContext';


const BirthCertificate = ({setShowErrorMessage,setShowWaiting}) => {

    const history = useHistory();
  
    const globalContext = useContext(AppContext);

    const handleClick = async() =>{
        history.push(process.env.REACT_APP_BASE_URL + 'certificateSelector');
    }
    return (
        <>
            <div className='CertificateContainer animated animatedFadeInUp fadeInUp dos'>
                <div className='BirthCertificateBtn' onClick={handleClick}>
                    <img src={Image} className='certbtnimage'/>
                    <p class="textbtnselector">Certificado de <span>Nacimiento</span></p>
                </div>
                
                </div>


        </>
    )
};

export default BirthCertificate;