import React, {useState,useContext,useEffect} from 'react';
import './GeneralPayOptions.css';
import { Container, Row, Col } from 'react-bootstrap';
import IrMenu from '../../../../../commons/components/Buttons/IrMenu/IrMenu';
import Close from '../../../../../commons/components/Buttons/Close/Close';
import NameUser from "../../../../../commons/components/UserNames/NameUser/NameUser";
import GeneralPayOptiondBtnPdf from './components/GeneralPayOptiondBtnPdf/GeneralPayOptiondBtnPdf';
import GeneralPayOptionBtnTGR from './components/GeneralPayOptiondBtnTGR/GeneralPayOptionBtnTGR';
import imageicon from "../../../../../assets/images/iconalert.png";

const GeneralPayOptions = ({description,detail,purchase,onClickFun, onClickpayWithCode ,amount, nameorange = 'DEF', setShowPlaces}) => {

    const showPlaces = () => {
        setShowPlaces(true)
    }
    
    return (
        <> 
           <div className="center-screen">
                <Container className="mincertificado contenedorreducido">
                        <div className="paddingmarriageuser">
                            <NameUser></NameUser>
                            <p className='animated animatedFadeInUp fadeInUp dos'>{description}</p>
                        </div>
                    <Row className="align-items-center">
                    <Col className="izqcertcontfloat animated animatedFadeInUp fadeInUp dos">
                        <h2 className='listatitle'>DATOS PERSONALES</h2>
                        <form className='formfact'>
                            <fieldset>
                                <input type="text" name="namefact" id="" value={purchase?.name+" "+purchase.lastName} placeholder="Nombre" disabled/>
                                <input type="text" name="dnifact" id="" value={purchase?.dniSolicitante}  placeholder="Identidad" disabled/>
                                <input type="text" name="descriptionfact" id="" value={detail} placeholder="Detalle de factura" disabled/>
                            </fieldset>
                        </form>
                        <div className='paydata'><p className='derpaydata'>Monto a pagar:</p> <p className='izqpaydata'>{amount}L.</p></div>
                    </Col>
                    <Col className="dercertcontfloat">
                        <GeneralPayOptiondBtnPdf onClickFun={onClickFun}></GeneralPayOptiondBtnPdf>
                        {nameorange === 'DNI' && 
                        <div className='contenedorcertificado animated animatedFadeInUp fadeInUp dos '>
                            <button onClick={showPlaces} className='lugarEntrega'>Si quieres modificar tu lugar de entrega por favor dar click aquí</button>
                        </div>
                        }
                        <p className='listatitle p-o animated animatedFadeInUp fadeInUp dos'>O</p>
                        <GeneralPayOptionBtnTGR onClickpayWithCode={onClickpayWithCode}></GeneralPayOptionBtnTGR>
                    </Col>                    
                    </Row>
                    <div className='animated animatedFadeInUp fadeInUp dos pay-info'>
                        <img src={imageicon} alt="Alert" className="iconmessage"/>
                        <h2>Revise detalladamente la información seleccionada y luego presione el botón de pago</h2>
                    </div>
                    <Row className="align-items-center">
                    <Col>
                        <div className="alignbtnfooter">
                            <div className="center">                                
                                <IrMenu></IrMenu>
                                <Close></Close>
                            </div>
                        </div>
                    </Col>                 
                    </Row>                    
                </Container>
                </div>
        </>
    )
};

export default GeneralPayOptions;