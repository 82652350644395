import React, { useState, useContext, useEffect } from "react";
import "./PreviewCertOptions.css";
import { Container, Row, Col } from "react-bootstrap";
import ImpresionPdf from "./components/ImpresionPdf/ImpresionPdf";
import IrMenu from "../../../../commons/components/Buttons/IrMenu/IrMenu";
import Close from "../../../../commons/components/Buttons/Close/Close";
import PreviewCertPdf from "./components/PreviewCertPdf/PreviewCertPdf";
import PreviewPDF from "./PreviewPDF/PreviewPDF";
import AppContext from "../../../../commons/components/AppContext/AppContext";
import Process from "../../../../commons/components/Process/Process";
import ErrorMessage from "../../../../commons/components/ErrorMessage/ErrorMessage";
import { sendToPrinter, storeBitacora } from "../../../../Services/services";
import Animationwaiting from "../../../../assets/images/ImpresionAnimacion.gif";
import alertIcon from "../../../../assets/images/iconalert.png";
import { useHistory } from "react-router-dom";

const PreviewCertOptions = () => {
     const globalContext = useContext(AppContext);
     const dni = globalContext.dni
     const [imageData, setImageData] = useState("");
     const [showErrorMessage, setShowErrorMessage] = useState(false);
     const [showPreviewPDF, setShowPreviewPDF] = useState(true);
     const [showProcess, setShowProcess] = useState(false);
     const history = useHistory();
     const [printAttempts, setPrintAttempts] = useState(0)

     useEffect(() => {
          console.log("cert en preview", globalContext.certificate.slice(0, 50));
          setImageData(globalContext.certificate);
          sendToPrint(true)
     }, [dni]); // eslint-disable-line react-hooks/exhaustive-deps

     const handleHidePreviewPDF = () => {
          setShowPreviewPDF(false);
     };
     const handleShowPreviewPDF = () => {
          setShowPreviewPDF(true);
     };

     const sendToPrint = async (confirmacion) => {
          setShowProcess(true);
          const result = await sendToPrinter(globalContext.certificate, 1, confirmacion);
          if (result && result?.status) {
               console.log("Go To Cierre");
               const bitacora = {
                    impresionCertificado1: {
                         status: true,
                         data: result,
                         timestamp: new Date().toISOString(),
                         dni: dni,
                    }
                };
                await storeBitacora(JSON.stringify(bitacora), globalContext.id_bitacora , true ,dni)
               history.push(process.env.REACT_APP_BASE_URL + "cierreApp");
               globalContext.setValidateDNI('')
          } else {
               const bitacora = {
                    impresionCertificado1: {
                         status: false,
                         data: result,
                         timestamp: new Date().toISOString(),
                         dni: dni,
                    }
                };
                await storeBitacora(JSON.stringify(bitacora), globalContext.id_bitacora , true ,dni)
               setShowErrorMessage(true);
               console.log("Error en impresion");
          }
          setShowProcess(false);
     };

     const onAccept = () => {
          setShowErrorMessage(false);
          console.log('entro a la function' + printAttempts)
          if (printAttempts < 3) {
               setPrintAttempts(printAttempts + 1);
               sendToPrint(true);
          } else {
               setShowErrorMessage(true);
               console.log("Se han hecho 3 intentos de impresión sin éxito. Redirigiendo a cierreApp");
               history.push(process.env.REACT_APP_BASE_URL + "cierreApp");
               globalContext.setValidateDNI('')
          }
     };

     return (
          <>
               <div className="center-screen">
                    <Container className="mincertificado">
                         <Row className="align-items-center">
                              <Col>
                                   <div className="alignbtnfooter">
                                        <div className="center">
                                             <IrMenu></IrMenu>
                                             <Close></Close>
                                        </div>
                                   </div>
                              </Col>
                         </Row>
                    </Container>
                    <Process show={showProcess} imageanimation={Animationwaiting} message="Su documento se está imprimiendo..." _classprocess="imgimpresion"></Process>
                    <ErrorMessage imageicon={alertIcon} show={showErrorMessage} message="No se pudo imprimir el documento. Por favor, inténtelo de nuevo." onAccept={onAccept}></ErrorMessage>
               </div>
          </>
     );
};

export default PreviewCertOptions;
