import React from 'react';
import './LogotipoRnp.css';
import Image from '../../../../assets/images/logotipo-inicio.png';
// import Video from '../../../../assets/videos/videoInicio.mp4';

import { useHistory } from "react-router-dom";

const LogotipoRnp = () => {

    const history = useHistory();
    const goTestVpos = () =>{
         history.push(process.env.REACT_APP_BASE_URL+"testVpos");

    }
    return (
        <img src={Image} className='logotipogif' />
    )
}

export default LogotipoRnp;