import React , {useContext} from 'react';
import './EndProcess.css';
import { useHistory } from "react-router-dom";
import AppContext from '../../AppContext/AppContext';
import { IoMdCloseCircle } from "react-icons/io";

const EndProcess = () => {
    const history = useHistory();
    const globalContext = useContext(AppContext);


    const handleCancel = () =>{
        history.push(process.env.REACT_APP_BASE_URL + 'cierreApp');
        globalContext.setValidateDNI('')
    }
    return (
        <>
            <div className='contenedorirmenu animated animatedFadeInUp fadeInUp dos'>
            <button className="button-finalizar" onClick={handleCancel}>
                    <IoMdCloseCircle /> &nbsp;
                    Finalizar
                </button>
            </div>
        </>
    )
};

export default EndProcess;