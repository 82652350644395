import React from 'react';
import './GeneralInactividad.css';
import IMAGEINACTIVIDAD from '../../../assets/images/rnp-inactividad.png';

const GeneralInactividad = ({  message, onAccept, imageicon }) => {


  return (
    <div className='error-overlay backgroundorange' onClick={onAccept}>.
      <div className='error-content inactividadcontent'>
      <img src={IMAGEINACTIVIDAD} alt="Alert" className="iconmessage"/>             
      <div className='error-message'>{message}</div>  
        <h2 className='contadorinactividad'>15</h2>      
        <p className='alertboton'>Presiona la pantalla</p>
        <p className='espacioboton'>Para continuar navegando en la aplicación</p>
      </div>
    </div>
  );
};

export default GeneralInactividad;
