import React from 'react';
import './GlobalTitleCertificateVertical.css';
import ImageNacimiento from '../../../../assets/images/certmatrimonio.png';

const GlobalTitleCertificateVertical = ({ imgcertificado, nameligth, namebold, nameorange}) => {
    
    return (
        <>     
            <div className='contenedortitle animated animatedFadeInUp fadeInUp one'>
                <div className='TitularCertificateBtn'>
                    <img src={imgcertificado} className='certbtnimage'/>
                    <p class="textbtnselector">{nameligth} <br />{namebold ? <strong>{namebold}<br /></strong>  : null}  <span>{nameorange}</span></p>
                </div>
               
            </div>
        </>
    )
};

export default GlobalTitleCertificateVertical;