import React, { useEffect, useContext,useState,useRef } from 'react';
import { useHistory } from "react-router-dom";
import AppContext from '../../commons/components/AppContext/AppContext';
import './DocumentSelector.css';
import ValidateButton from '../Authentication/components/ValidateButton/ValidateButton';
import DocumentsOptions from './components/DocumentsOptions/DocumentsOptions';
import LogotipoRnp from '../DocumentSelector/components/LogotipoRnp/LogotipoRnp';
import Wainting from '../../commons/components/Waiting/Waiting';
import ErrorMessage from '../../commons/components/ErrorMessage/ErrorMessage';

const DocumentSelector = () => {

    //global context
    const globalContext = useContext(AppContext);
    const [showWaiting, setShowWaiting] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    useEffect(() => {
      
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

  
    const onAccept= ()=>{
        setShowErrorMessage(false)
    }
    return (
        <div className='documentSelector'>
            <div className='contenedorkiosko'>
                
                <DocumentsOptions setShowErrorMessage={setShowErrorMessage} setShowWaiting={setShowWaiting}>  </DocumentsOptions>

                <Wainting show={showWaiting}></Wainting>
                <ErrorMessage  show={showErrorMessage} message="No se pudo obtener el certificado. Por favor, inténtelo de nuevo." onAccept={onAccept} ></ErrorMessage>
            
            </div>
        </div>
    )
};

export default DocumentSelector;
