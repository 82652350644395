import React from 'react';
import GlobalTitleCertificate from '../../commons/components/TitulosCertificados/GlobalTitleCertificate/GlobalTitleCertificate';
import ImageNacimiento from '../../assets/images/certdefuncion.png';
import './SearchDeath.css';
import DocumentsOptions from './components/DocumentsOptions/SearchDeathOptions';
import LogotipoRnp from '../../commons/components/Logotipo/LogotipoRnp/LogotipoRnp';


const SearchDeath = () => {  

    return (
        <div className='certificateSelector'>
            <div className='contenedorkiosko'>
                <div class="contentlogotipo">
                    <LogotipoRnp></LogotipoRnp>
                    <GlobalTitleCertificate imgcertificado={ImageNacimiento} nameligth="Certificado de" namebold="" nameorange="Defunción" ></GlobalTitleCertificate>
                </div>
                <DocumentsOptions>  </DocumentsOptions>
            </div>
        </div>
    )
};

export default SearchDeath;
