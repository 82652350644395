import React , {useState}from 'react';
import GlobalTitleCertificate from '../../commons/components/TitulosCertificados/GlobalTitleCertificate/GlobalTitleCertificate';
import ImageNacimiento from '../../assets/images/certmatrimonio.png';
import './CertificateMarriage.css';
import DocumentsOptions from './components/DocumentsOptions/CertificateMarriageOptions';
import LogotipoRnp from '../../commons/components/Logotipo/LogotipoRnp/LogotipoRnp';
import Wainting from '../../commons/components/Waiting/Waiting';
import ErrorMessage from '../../commons/components/ErrorMessage/ErrorMessage';


const CertificateMarriage = () => {  
    const [showWaiting, setShowWaiting] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    const onAccept= ()=>{
        setShowErrorMessage(false)
    }
    return (
        <div className='certificateSelector'>
            <div className='contenedorkiosko'>
                <div class="contentlogotipo">
                    <LogotipoRnp></LogotipoRnp>
                    <GlobalTitleCertificate imgcertificado={ImageNacimiento} nameligth="Certificado de" namebold="" nameorange="Matrimonio" ></GlobalTitleCertificate>
                </div>
                <DocumentsOptions setShowWaiting={setShowWaiting} showErrorMessage={showErrorMessage}>  </DocumentsOptions>
                <Wainting show={showWaiting}></Wainting>
                <ErrorMessage  show={showErrorMessage} message="No se pudo obtener el certificado. Por favor, inténtelo de nuevo." onAccept={onAccept} ></ErrorMessage>
            
          
            </div>
        </div>
    )
};

export default CertificateMarriage;
