import React,{ useState, useContext } from 'react';
import './FacialRecognitionButton.css';
import FacialCapture from './componets/FacialCapture/FacialCapture';
import AppContext from '../../../../../../commons/components/AppContext/AppContext';
import ErrorMessage from '../../../../../../commons/components/ErrorMessage/ErrorMessage';

const FacialRecognitionButton = ({_onClick}) => {
    //{_setFacial,facial,_setFinger}
    
    const globalContext = useContext(AppContext);
    const activeClass = `contenedorbtnautentica ${!globalContext.validateDNI? 'btnfacialdisable' : ''}`;
    const imageClass = `${!globalContext.validateDNI? 'btnDisableFaceprint' : 'btnEnableFaceprint'}`
    
    const activeButton = () => {
        if (globalContext.validateDNI) {
            globalContext.handleClickModalFacial();
        }
    }
    return (
        <>
        
            <div className={`FaceContainer animated animatedFadeInUp fadeInUp one ${globalContext.facial ? 'btnactivoautentica' : ''}`}  onClick={activeButton}>
                <div className={activeClass}>
                    <div className={imageClass}></div>
                    <span className="btnselectautentica">Reconocimiento Facial</span>
                </div>
                            
            </div>
            <FacialCapture _onClick={_onClick}></FacialCapture>
        </>
    )
};

export default FacialRecognitionButton;
